import QrcodeService from "@/core/services/qrcode.service";
import ErrHandler from "@/core/services/errorHandle.service";
import { SEND_SNACKBAR } from "./snackbar.module";
import i18n from "@/core/plugins/vue-i18n";

/*================
   ACTIONS START
==================*/
export const STORE_QR = "qrcode/storeQr";
// export const CREATE_TRANS_QR = "qrcode/createTransQr";
export const DELETE_QR = "qrcode/deleteQr";
export const UPDATE_QR = "qrcode/updateQr";
export const UPDATE_QR_DATA = "qrcode/updateQrData";
export const CREATE_QR = "qrcode/createQr";
export const CHANGE_QR_TYPE = "qrcode/changeQrType";
// export const RE_CREATE_TRANS_QR = "qrcode/reCreateTransQr";
export const EDIT_QR = "qrcode/editQr";
export const FETCH_USER_QR = "qrcode/fetchQrCodes";
export const FETCH_USER_FOLDER_QR_LIST = "qrcode/fetchUserFolderQrCodes";
export const FETCH_TYPES = "qrcode/fetchTypes";
export const FETCH_USER_QR_TYPES = "qrcode/fetchUserQrTypes";
export const FETCH_PACKAGE_DETAILS = "qrcode/fetchPackageDetails";

/* User Design Start */
export const FETCH_ALL_USER_DESIGNS = "qrcode/fetchAllUserDesigns";
export const GET_USER_DESIGN = "qrcode/getUserDesign";
export const SEND_USER_DESIGN = "qrcode/sendUserDesign";
export const EDIT_USER_DESIGN = "qrcode/editUserDesign";
export const DELETE_USER_DESIGN = "qrcode/deleteUserDesign";
export const PURGE = "qrcode/purge";
/* End */

/*__
 ACTIONS END
__*/

/*===================
   MUTATIONS START
====================*/
export const SET_QRCODES = "qrcode/setQrCodes";
export const SET_USER_QR_TYPES = "qrcode/setUserQrTypes";
export const SET_PREVIEW_PATH = "qrcode/setPreviewPath";
export const SET_ACTIVE_TYPE = "qrcode/setActiveType";
export const SET_ACTIVE_TEMP = "qrcode/setActiveTemp";
export const PURGE_PROP = "qrcode/purgeProp";

/* User Design Start */
export const SET_ACTIVE_FRAME_INDEX = "qrcode/setActiveFrameIndex";
export const SET_ACTIVE_FRAME = "qrcode/setActiveFrame";
export const SET_PROCESSED_QRCODE = "qrcode/setProcessedQrcode";
export const SET_USER_DESIGNS = "qrcode/setUserDesigns";
/* End */

/*__
 MUTATIONS END
__*/

const state = {
  qrCodes: [],
  savedDesigns: [],
  activeFrameIndex: 0,
  activeFrame: {},
  processedQrcode: {
    config: null,
    frame: null,
    svg: "",
    url: "",
    data: null,
    type: "",
  },
  createdQrTypes: [],
  previewPath: "",
  activeType: null,
  activeTemp: "",
  redirectProp: {},
  packageDetails: {},
};

const getters = {
  currentQrCodes(state) {
    return state.qrCodes;
  },
  getQrCodeById: () => async (payload) => {
    try {
      return (await QrcodeService.fetchQr(payload)).data;
    } catch (error) {
      ErrHandler(error);
      console.error(error);
      return null;
    }
  },
  currentCreatedQrTypes(state) {
    return state.createdQrTypes;
  },
  previewPath(state) {
    return state.previewPath;
  },
  activeType(state) {
    return state.activeType;
  },
  activeTemp(state) {
    return state.activeTemp;
  },
  // Qr Customization
  getActiveFrameIndex(state) {
    return state.activeFrameIndex;
  },
  getActiveFrame(state) {
    return state.activeFrame;
  },
  getProcessedQrcode(state) {
    return state.processedQrcode;
  },
  getSavedDesigns(state) {
    return state.savedDesigns;
  },
  getRedirectProp(state) {
    return state.redirectProp;
  },
  getPackageDetails(state) {
    return state.packageDetails;
  },
};

const actions = {
  /*
   * Payload obj has config, and data props
   * */

  async [STORE_QR](context, payload) {
    const data = {};
    const standartConfig = {
      eye: "frame0",
      body: "rounded-in-smooth",
      brf1: [],
      brf2: ["fh"],
      erf1: [],
      brf3: ["fv"],
      erf2: ["fh"],
      erf3: ["fv"],
      bgColor: "",
      bodyColor: "#000000",
      eyeBall: "ball0",
      eye1Color: "#000000",
      eye2Color: "#000000",
      eye3Color: "#000000",
      logo: null,
      eyeBall1Color: "#000000",
      eyeBall2Color: "#000000",
      eyeBall3Color: "#000000",
    };
    const bannerConfig = { frameColor: "0,0,0", backColor: "0,0,0", size: "1080", x: "0", y: "0", level: 0 };

    data.data = payload.data;
    data.type = payload.type;
    data.format = payload.format;
    data.web_template_id =
      payload.web_template_id ??
      context.state.activeType.templates.find((e) => e.tag == context.state.activeTemp)?.id ??
      7;
    data.type_id = payload.typeId ?? context.state.activeType.id;
    data.qrType = payload.qrType;
    data.isParent = payload.isParent ? 1 : 0;
    if (payload.qrType == "standart") data.config = standartConfig;
    if (payload.qrType == "banner") data.config = bannerConfig;

    try {
      const response = await QrcodeService.storeQrCode(data);
      await context.dispatch(FETCH_USER_QR_TYPES);
      // context.commit(SET_USER_QR_TYPES, response.data.type);
      context.dispatch(
        SEND_SNACKBAR,
        {
          message: i18n.t("SNACKBAR.qrcode_created"),
          color: "green",
        },
        {
          root: true,
        }
      );
      //
      // context.dispatch("auth/fetchUser", null, { root: true });
      context.dispatch(FETCH_PACKAGE_DETAILS);
      return response.data;
    } catch (err) {
      ErrHandler(err);

      return null;
    }
  },
  async [CREATE_QR](context, payload) {
    try {
      const response = await QrcodeService.createQr(payload);

      return response.data;
    } catch (err) {
      ErrHandler(err);
      return err.message;
    }
  },

  async [UPDATE_QR](context, payload) {
    try {
      const response = await QrcodeService.updateQrCode(payload);

      context.dispatch(
        SEND_SNACKBAR,
        {
          message: i18n.t("SNACKBAR.qrcode_updated"),
          color: "green",
        },
        {
          root: true,
        }
      );
      return response.data;
    } catch (err) {
      ErrHandler(err);
    }
  },
  async [UPDATE_QR_DATA](context, payload) {
    try {
      const response = await QrcodeService.updateQrData(payload);

      context.dispatch(
        SEND_SNACKBAR,
        {
          message: i18n.t("SNACKBAR.qrcode_updated"),
          color: "green",
        },
        {
          root: true,
        }
      );
      return response.data;
    } catch (err) {
      ErrHandler(err);
      console.error(err);
    }
  },
  /**
   * @param {data, type, web_template_id, type_id, qrcodeId} payload
   * @returns {qrcode}
   */
  async [CHANGE_QR_TYPE](context, payload) {
    try {
      const response = await QrcodeService.changeQrType(payload);
      return response.data;
    } catch (error) {
      ErrHandler(error);
    }
  },

  async [DELETE_QR](context, id) {
    try {
      const response = await QrcodeService.deleteQrCode(id);
      await context.dispatch(FETCH_USER_QR_TYPES);
      context.dispatch(FETCH_PACKAGE_DETAILS);
      context.dispatch("auth/fetchUser", null, { root: true });
    } catch (error) {
      ErrHandler(error);
    }
  },

  async [FETCH_USER_QR](context, options) {
    const opt = options ?? {
      type: null,
      status: null,
      page: null,
      sort: null,
      search: null,
      master: null,
      parent_id: null,
    };
    try {
      const response = await QrcodeService.fetchQrCodes(opt);
      return response.data;
    } catch (error) {
      if (error.response.data == "err_25") {
        options.page = 1;
        return await context.dispatch("qrcode/fetchQrCodes", options);
      }
      return ErrHandler(error);
    }
  },
  async [FETCH_USER_FOLDER_QR_LIST](ctx, { options, folderId }) {
    try {
      for (const [key, value] of Object.entries(options)) {
        if (!value) delete options[key];
      }
      const searchParams = new URLSearchParams(options);
      const response = await QrcodeService.fetchUserFolderQrCodes(searchParams, folderId);
      return response.data;
    } catch (error) {
      this.$errorHandler(error);
    }
  },
  async [FETCH_USER_QR_TYPES](ctx, payload) {
    try {
      const res = await QrcodeService.fetchUserQrTypes();

      ctx.commit(SET_USER_QR_TYPES, res.data);
      return res.data;
    } catch (error) {
      return ErrHandler(error);
    }
  },
  async [FETCH_TYPES](ctx, type) {
    try {
      const res = await QrcodeService.fetchTypes(type);
      return res.data.data; //.filter((e) => e.code !== "document");
    } catch (err) {
      return ErrHandler(err);
    }
  },
  /*
   ** User Designs
   */
  async [FETCH_ALL_USER_DESIGNS](context) {
    try {
      const response = (await QrcodeService.getAllUserDesigns()).data.data;
      return response;
    } catch (err) {
      ErrHandler(err);
    }
  },
  async [SEND_USER_DESIGN](context, payload) {
    try {
      const data = {};
      data.frame = payload.frame;
      data.svg = payload.svg;
      data.config = payload.config;
      data.svgFrameless = payload.svgFrameless;
      data.placeholder = payload.placeholder;
      const response = (await QrcodeService.sendUserDesign(data)).data;
    } catch (err) {
      ErrHandler(err);
    }
  },
  async [DELETE_USER_DESIGN](context, id) {
    try {
      const response = (await QrcodeService.deleteUserDesign(id)).data;

      await context.dispatch(FETCH_ALL_USER_DESIGNS);
    } catch (err) {
      ErrHandler(err);
    }
  },
  async [FETCH_PACKAGE_DETAILS](context) {
    try {
      const response = await QrcodeService.fetchPackageDetails(context.rootGetters.currentUser.id);
      if (!response?.data?.package) {
        context.state.packageDetails = {};
        return;
      }
      for (const [key, value] of Object.entries(response.data.package)) {
        if (key.includes("scan")) continue;
        response.data.package[key] = parseInt(value);
      }

      context.state.packageDetails = response.data;
    } catch (error) {
      ErrHandler(error);
      return null;
    }
  },
};

/*===========
   MUTATIONS
=============*/

const mutations = {
  [SET_QRCODES](state, payload) {
    state.qrCodes = payload;
  },
  [SET_USER_QR_TYPES](state, payload) {
    const keys = Object.keys(payload);

    const arr = new Array(keys.length).fill({}).map((e, i) => {
      return { text: i18n.t("qrcode_" + keys[i]).capitalize(), value: keys[i] };
    });
    const sortedArr = arr.sort((a, b) => {
      let _a = a.text.replaceAll(/ç/gi, "C");
      _a = _a.replaceAll(/ş/gi, "S");
      let _b = b.text.replaceAll(/ç/gi, "C");
      _b = _b.replaceAll(/ş/gi, "S");

      if (_a.toUpperCase() < _b.toUpperCase()) return -1;
      if (_a.toUpperCase() > _b.toUpperCase()) return 1;

      return 0;
    });

    // for (let i = 0; i < keys.length; i++) {
    //   if (keys[i].includes("static")) {
    //     keys[i] = keys[i].slice(0, -6) + " static";
    //   }
    //   if (keys[i].includes("dynamic")) {
    //     keys[i] = keys[i].slice(0, -7) + " dynamic";
    //   }
    // }
    state.createdQrTypes = sortedArr;
  },

  [SET_PREVIEW_PATH](state, payload) {
    state.previewPath = payload;
  },
  [SET_ACTIVE_TEMP](state, payload) {
    state.activeTemp = payload;
  },
  [SET_ACTIVE_TYPE](state, payload) {
    state.activeType = payload;
  },

  /*
   ** User Designs
   */
  [SET_PROCESSED_QRCODE](state, payload) {
    state.processedQrcode = JSON.parse(JSON.stringify(payload));
  },
  [SET_USER_DESIGNS](state, payload) {
    state.savedDesigns = payload;
  },
  [SET_ACTIVE_FRAME_INDEX](state, payload) {
    state.activeFrameIndex = parseInt(payload);
  },
  [SET_ACTIVE_FRAME](state, frame) {
    state.activeFrame = frame;
  },
  [PURGE_PROP](state, payload) {
    state[payload] = null;
  },
  [PURGE](state) {
    const defaultState = {
      qrCodes: [],
      savedDesigns: [],
      activeFrameIndex: 0,
      activeFrame: {},
      processedQrcode: {
        config: null,
        frame: null,
        svg: "",
        url: "",
        data: null,
        type: "",
      },
      createdQrTypes: [],
      previewPath: "",
      activeType: null,
      activeTemp: "",
      redirectProp: {},
      packageDetails: {},
    };

    Object.keys(defaultState).forEach((key) => {
      state[key] = defaultState[key];
    });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
