// GERMANY
export const locale = {
  greet: ({ named }) => `Hallo <span class="font-weight-bold">${named("userName")}!</span>`,
   spanish: "Spanish",
  german: "Germany",
  turkish: "Türkçe",
  english: "English",
  true: "Ja",
  false: "Nein",
  date: "Datum",
  time: "Uhrzeit",
  dynamic: "Dynamisch",
  static: "Statisch",
  hi: "Hallo",
  create: "Erstellen",
  update: "Aktualisieren",
  change: "Ändern",
  template: "Vorlage",
  qr_list: "QR-Liste",
  QR_TITLE: "QR-Code Titel",
  qr_code_list: "QR-Code Liste",
  qrcode_document: "Dokument",
  qrcode_document_subtext: "Teilen Sie Ihre Dokumente",
  qrcode_website: "Website (Link)",
  qrcode_website_subtext: "Leiten Sie Ihre Links weiter",
  qrcode_vcard: "Vcard Plus",
  qrcode_vcard_subtext: "Erstellen Sie eine V-Card mit Kontaktinformationen",
  qrcode_smsdynamic: "SMS",
  qrcode_smsdynamic_subtext: "Erstellen Sie einen SMS-Absender",
  qrcode_sms: "SMS",
  qrcode_sms_subtext: "Erstellen Sie eine SMS-Nachricht",
  qrcode_business: "Visitenkarte",
  qrcode_business_subtext: "Erstellen Sie Ihre Unternehmensseite",
  qrcode_socialmedia: "Soziale Medien",
  qrcode_socialmedia_subtext: "Leiten Sie zu Ihren sozialen Medienkonten weiter",
  qrcode_pdf: "PDF",
  qrcode_pdf_subtext: "Erstellen Sie einen PDF-Viewer",
  qrcode_restaurant: "Restaurant Menü",
  qrcode_restaurant_subtext: "Erstellen Sie ein erweitertes, spezielles Menü für Ihr Restaurant!",
  qrcode_pdfmenu: "PDF Katalog / Menü",
  qrcode_pdfmenu_subtext: "Erstellen Sie einen Katalog oder ein Menü, indem Sie mehrere PDFs hochladen",
  qrcode_catalog: "Online Katalog / Menü",
  qrcode_catalog_subtext: "Erstellen Sie einen Katalog aus der von Ihnen gewählten PDF",
  qrcode_equipment: "Ausrüstungs-Karte",
  qrcode_equipment_subtext: "Verfolgen Sie den Zustand Ihrer Ausrüstung",
  qrcode_staffcard: "Mitarbeiterkarte",
  qrcode_staffcard_subtext: "Erstellen Sie eine Mitarbeiterkarte für Ihre Angestellten",
  qrcode_emergency: "Notfall",
  qrcode_emergency_subtext: "Notfallinformationskarte",
  qrcode_event: "Veranstaltung",
  qrcode_event_subtext: "Einladungsanwendung für Konzerte, Messen usw.",
  qrcode_location: "Standort",
  qrcode_location_subtext: "Erstellen Sie eine Wegbeschreibung zu den Adressen Ihrer Organisation",
  qrcode_music: "Musik",
  qrcode_music_subtext: "Laden Sie ein Lied oder eine Audiodatei hoch und spielen Sie es ab",
  qrcode_pet: "Haustier",
  qrcode_pet_subtext: "Erstellen Sie eine Tracking-Karte mit den Informationen Ihres Haustieres",
  qrcode_museum: "Museum",
  qrcode_museum_subtext: "Erstellen Sie eine Informationskarte zu historischen Artefakten oder Orten",
  qrcode_coupon: "Gutschein",
  qrcode_coupon_subtext: "Erstellen Sie Gutscheine für Ihre Sonderaktionen",
  qrcode_rate: "Bewertung",
  qrcode_rate_subtext: "Lassen Sie Benutzer Ihr Produkt bewerten und Kommentare hinterlassen",
  qrcode_store: "Apps",
  qrcode_store_subtext: "Erstellen Sie Links zu Ihren Apps im Apple Store oder Google Play",
  qrcode_call: "Anruf",
  qrcode_call_subtext: "Erstellen Sie eine Anrufkarte mit Telefonnummer",
  qrcode_text: "Text",
  qrcode_text_subtext: "Teilen Sie einen beliebigen Text, den Benutzer kopieren können",
  qrcode_mail: "E-Mail",
  qrcode_mail_subtext: "Erstellen Sie eine E-Mail-Kontaktkarte",
  qrcode_whatsapp: "WhatsApp",
  qrcode_whatsapp_subtext: "Erstellen Sie einen WhatsApp-Kontaktlink",
  qrcode_instagram: "Instagram",
  qrcode_instagram_subtext: "Erstellen Sie einen Instagram-Kontaktlink",
  qrcode_facebook: "Facebook",
  qrcode_facebook_subtext: "Erstellen Sie einen Facebook-Kontaktlink",
  qrcode_twitter: "X / Twitter",
  qrcode_twitter_subtext: "Erstellen Sie einen X / Twitter-Kontaktlink",
  qrcode_linkedin: "LinkedIn",
  qrcode_linkedin_subtext: "Erstellen Sie einen LinkedIn-Kontaktlink",
  qrcode_youtube: "YouTube",
  qrcode_youtube_subtext: "Teilen Sie ein YouTube-Video",
  qrcode_pinterest: "Pinterest",
  qrcode_pinterest_subtext: "Erstellen Sie einen Pinterest-Kontaktlink",
  qrcode_paypal: "PayPal",
  qrcode_paypal_subtext: "Erstellen Sie einen PayPal-Zahlungs- oder Spendenlink",
  qrcode_wifidynamic: "Wi-Fi",
  qrcode_wifidynamic_subtext: "Teilen Sie die Informationen zu Ihrer Wi-Fi-Verbindung",
  qrcode_websitestatic: "Website (statisch)",
  qrcode_websitestatic_subtext: "Statischer QR-Code mit der URL Ihrer Website",
  qrcode_vcardstatic: "VCard (statisch)",
  qrcode_vcardstatic_subtext: "Statischer QR-Code, der als .vcf auf einem Telefon gespeichert werden kann",
  qrcode_phone: "Telefon",
  qrcode_phonestatic: "Telefon (statisch)",
  qrcode_phone_subtext: "Telefonnummer",
  qrcode_textstatic: "Text (statisch)",
  qrcode_textstatic_subtext: "Statischer Text",
  qrcode_smsstatic: "SMS (statisch)",
  qrcode_smsstatic_subtext: "Statische SMS",
  qrcode_wifistatic: "Wi-Fi (statisch)",
  qrcode_wifistatic_subtext: "Statische Wi-Fi-Verbindung",
  qrcode_multiplelink: "Mehrere Links",
  qrcode_multiplelink_subtext: "Fügen Sie mehrere Links mit zufälliger Weiterleitung hinzu",
  qrcode_gallery: "Bildergalerie",
  qrcode_gallery_subtext: "Bildergalerie",
  qrcode_customerservice: "Abteilungs-Kontakt",
  qrcode_customerservice_subtext: "Erstellen Sie Kontaktlinks für Ihre Abteilungen",
  qrcode_title_wifi: "Wi-Fi",
  qrcode_title_vcard: "VCard Plus",
  qrcode_title_sms: "SMS",
  qrcode_title_gallery: "Galerie",
  qrcode_title_multiplelink: "Mehrere Links",
  qrcode_title_music: "Musik",
  qrcode_title_website: "Website",
  qrcode_title_whatsapp: "WhatsApp",
  qrcode_title_email: "E-Mail",
  qrcode_title_pdfmenu: "PDF Katalog / Menü",
  qrcode_title_catalog: "Online Katalog / Menü",
  qrcode_title_text: "Text",
  qrcode_title_coupon: "Gutschein",
  qrcode_title_pinterest: "Pinterest",
  qrcode_title_instagram: "Instagram",
  qrcode_title_twitter: "Twitter",
  qrcode_title_rate: "Bewertung",
  qrcode_title_business: "Visitenkarte",
  qrcode_title_phone: "Telefon",
  qrcode_title_staffcard: "Mitarbeiterkarte",
  qrcode_title_paypal: "PayPal",
  qrcode_title_store: "Apps",
  qrcode_title_call: "Anruf",
  qrcode_title_location: "Standort",
  qrcode_title_pdf: "PDF",
    qrcode_title_museum: "Museum",
  qrcode_title_linkedin: "LinkedIn",
  qrcode_title_youtube: "YouTube",
  qrcode_title_pet: "Haustier",
  qrcode_title_facebook: "Facebook",
  qrcode_title_event: "Veranstaltung",
  qrcode_title_equipment: "Ausrüstungskarte",
  qrcode_title_emergency: "Notfallkarte",
  qrcode_title_restaurant: "Restaurant",
  qrcode_title_socialmedia: "Soziale Medien",
  qrcode_all: "- Alle anzeigen -",
  page_not_found_text: "Hoppla! Der Inhalt, den Sie suchen, wurde nicht gefunden",
  go_home: "Startseite",
  link_is_copied_to_clipboard: "Link wurde kopiert!",
  select_pdf_by_clicking: "Aktivieren Sie die PDF-Datei, indem Sie auf die Datei klicken",
  select_content_by_clicking: "Aktivieren Sie die Datei, indem Sie darauf klicken",
  clear_all_fields: "Formular löschen",
  search_type: "Kartentyp wählen",
  empty: "Leer",
  click_lock_to_unlock: "Klicken Sie auf das Schloss-Symbol, um dieses Feld zu entsperren",
  user_folder: "Benutzerordner",
  this_field_is_required: "Dieses Feld ist erforderlich",
  you_dont_have_any_saved_design: "Speichern Sie das Design und verwenden Sie es bei den nächsten QR-Codes",
  you_can_save_your_current_design_by_clicking_button_below: "Später können Sie denselben Designstil wiederverwenden, ohne Farben und Formen erneut auszuwählen, indem Sie einfach auf den Button klicken.",
  you_have_to_update_save_this_qrcode_to_be_able_to_use_this_feature: "Um diese Funktion nutzen zu können, müssen Sie diesen QR-Code speichern.",
  SAVE_CURRENT_DESIGN: " + SPEICHERN SIE DAS AKTUELLE DESIGN",
  you_have_to_update_current_changes_first: "Aktualisieren Sie zuerst die aktuellen Änderungen",
  scan_result_good: "Gut lesbar",
  scan_result_bad: "Schlecht lesbar",
  LANGUAGE: "Sprache auswählen",
  YES: "JA",
  ACTIVE_LANGUAGE: "Aktive Sprache",
  ADD_SOME_INFO: "Informationen eingeben",
  CONTACT_INFO: "Kontaktinformationen",
  FULL_NAME: "Vollständiger Name",
  FIRSTNAME: "Vorname",
  LASTNAME: "Nachname",
  COMPANY_NAME: "Firmenname",
  COMPANY_ADDRESS: "Firmenadresse",
  JOB_TITLE: "Berufsbezeichnung",
  JOBTITLE: "Berufsbezeichnung",
  DEPARTMENT: "Abteilung",
  BLOOD_GROUP: "Blutgruppe",
  FOLDER_NO: "Ordnernummer",
  DATE_OF_START: "Startdatum",
  COMPANY: "Firma",
  COUNTRY: "Land",
  STATE: "Bundesland",
  MAIL: "E-Mail",
  WEBSITE: "Website",
  PHONE: "Telefon",
  SMS: "SMS",
  HOME: "Startseite",
  WHO_ARE_WE: "Wer sind wir",
  icon: "Symbol",
  show_more: "Mehr anzeigen",
  remove_this_song: "Dieses Lied entfernen",
  select_cover_image: "Cover-Bild auswählen",
  select_audio_file: "Audiodatei auswählen",
  delete_all: "Alles löschen",
  spotify_settings: "Spotify-Einstellungen",
  light_theme: "Helles Design",
  dark_theme: "Dunkles Design",
  you_can_paste_single_song_or_playlist: "Sie können ein einzelnes Lied oder eine Playlist einfügen. Der richtige Link sieht so aus:",
  add_songs_from_local_files: "Lieder aus lokalen Dateien hinzufügen",
  add_songs_from_spotify: "Lieder von Spotify hinzufügen",
  SETTINGS: "Einstellungen",
  APPLY: "ANWENDEN",
  crops_image: "Bild auf die gewählte Karte übertragen",
  rotate_right: "Nach rechts drehen",
  rotate_left: "Nach links drehen",
  upload_image: "Bild von Ihrem Computer hochladen",
  reset: "Formular zurücksetzen",
  upload: "Von PC hochladen",
  transfer: "Bild anzeigen",

    adress_icon: "Adresse Icon",
  area_icon: "Bereich Icon",
  
  card_owner: "Karteninhaber",
  expires_at: "Ablaufdatum",
  mounth: "MONAT",
  years: "JAHR",
  full_name: "Vollständiger Name",

  type_city_name: "Geben Sie Stadt und Landkreis ein",
  your_zip_code: "Postleitzahl",
  id_number: "Personalausweisnummer",
  tax_number: "Steuernummer",
  tax_office: "Finanzamt",

  qr_code_type: "Sie können den QR-Code-Typ ändern. (Wichtig!)",

  WHO_ARE_WE_1: "Unsere vollständig ausgestattete Software-Technologiefirma wurde 1997 mit dem Motto „Es gibt noch viel zu tun, viel zu programmieren“ gegründet. Unser Gründer träumte von einer Softwarefirma, die seinen Kunden echten Mehrwert bietet und messbare Ergebnisse liefert. Heute übertreffen wir diesen Traum bei weitem und bieten weiterhin unsere Dienstleistungen an.",
  
  WHO_ARE_WE_2: "Wir haben schon viel erreicht. Im Laufe der Jahre haben wir talentierten Softwaregenies die Tür geöffnet und gemeinsam sind wir stärker geworden. Wir kombinieren das Wissen, das wir gesammelt haben, mit der heutigen Technologie und setzen unsere Reise fort, indem wir die Vorteile dessen genießen, was uns die Leidenschaft für unsere Arbeit gebracht hat.",
  
  WHO_ARE_WE_3: "Mit unserem Wissen und unserer Erfahrung in der Branche werden wir mit einem ganzheitlichen Ansatz und nachhaltigen Softwaretechnologien Ihre digitale Stärke weiter ausbauen. Mit unseren Dienstleistungen in den Bereichen Markenmanagement, Webdesign und -entwicklung, digitale Marketingstrategien, Social Media Design, SEM, SEO, E-Commerce-Systeme und maßgeschneiderte Softwareprojekte begleiten wir Unternehmen in ihrer Markenbildung und übernehmen alle Prozesse von der Produktion bis zum Verkauf, von der Finanzierung bis zum Marketing und von der F&E bis zum Einkauf.",
  
  WHO_ARE_WE_4: "Unabhängig von der Größe oder dem Wert eines Projekts zeigen wir immer die gleiche Sorgfalt und Aufmerksamkeit. Unser Ruf basiert auf den zufriedenen Kunden, die durch die Projekte, die wir abgeschlossen haben, ihr Geschäft erweitern konnten.",
  
  WHO_ARE_WE_5: "Wir wissen, dass der erste Schritt bei jedem neuen Projekt das Zuhören ist. Wir verstehen genau, was Sie brauchen, welche Probleme Sie haben und welche Pläne Sie für die Zukunft haben. Auf dieser Grundlage planen wir umfassende Strategien, die wirklich funktionieren.",
  
  WHO_ARE_WE_6: "Mit unserer langjährigen Erfahrung wissen wir, dass wir Ihnen helfen können, unabhängig von der Größe Ihres Projekts oder Unternehmens. Unsere grundlegenden Prinzipien sind kontinuierliche Entwicklung, Empathie, Liebe zum Detail, Ehrlichkeit und Motivation. Dies ist der Grund, warum die Kunden, mit denen wir seit der Gründung unseres Unternehmens zusammenarbeiten, bei uns bleiben.",
  
  WHO_ARE_WE_7: "Aber das ist noch nicht alles! Jetzt wollen wir Ihre Träume, Ziele, Projekte und Ideen hören! Wenn Sie über Ihr Online-Geschäft mit uns sprechen möchten, steht unser gesamtes Team bereit und ist voller großartiger Ideen. Wir mögen digitale Kommunikation, aber wir mögen auch Telefongespräche. Sie können uns unter info@medyax.com eine E-Mail senden.",

    PHONEHOME: "Festnetztelefon",
  PHONEMOBILE: "Mobiltelefon",
  PHONEOFFICE: "Bürotelefon",
  PHONEHOMECOUNTRYCODE: "Ländervorwahl für Festnetztelefon",
  PHONEMOBILECOUNTRYCODE: "Ländervorwahl für Mobiltelefon",
  PHONEOFFICECOUNTRYCODE: "Ländervorwahl für Bürotelefon",
  ABOUT: "Über uns",
  ABOUTUS: "Über uns",
  TEAM: "Team",
  CONTACT: "Kontakt",
  PREVIEW: "QR-Code Vorschau",
  SUPPORT: "Hilfe",
  ADDRESS: "Adresse",
  ENTER_LOCATION: "+ Bitte geben Sie Ihre Adresse ein (Mehrere Adressen können hinzugefügt werden)",
  ENTER_LOCATION_HELP: "Bitte geben Sie unten Ihre Adresse ein. Wählen Sie dann Ihre Adresse aus dem angezeigten Fenster aus. Danach können Sie Ihre genaue Position mit dem roten Icon markieren.",
  PLEASE_CHOOSE_LOCATION: "Bitte wählen Sie zuerst einen Standort",
  MANAGER_FULL_NAME: "Name des Managers",
  MANAGER_PHONE: "Telefon des Managers",
  MANAGER_JOBTITLE: "Position des Managers",
  MANAGER_MAIL: "E-Mail des Managers",
  ART_NAME: "Kunstwerkname",
  ART_ACCESSION_NUMBER: "Zugangsnr.",
  ART_ERA: "Epoche",
  ART_MEASUREMENT: "Maße",
  ART_DISCOVERY_LOCATION: "Fundort",
  ART_ACCESSION_DATE: "Zugangsdaten",
  ART_DESCRIPTION: "Kunstwerkbeschreibung",
  RESTAURANT_ABOUT_US: "Über uns (Restaurant)",
  RESTAURANT_ADDRESS: "Restaurantadresse",
  RESTAURANT_PHONE: "Restauranttelefon",
  RESTAURANT_EMAIL: "Restaurant E-Mail",
  NAME: "Name",
  BLOODGROUP: "Blutgruppe",
  MEDICATION: "Eingenommene Medikamente",
  PLATENUMBER: "Kennzeichen",
  ALLERGICTO: "Allergisch gegen",
  DESCRIPTION: "Beschreibung",
  MEDICALRECORD: "Medizinische Akte",
  NUMBER: "Nummer",
  SERIALNUMBER: "Seriennummer",
  NEXTMAINTENANCE: "Nächstes Wartungsdatum",
  LASTMAINTENANCE: "Letzte Wartung",
  REPAIRCOMPANY: "Wartungs-/Kalibrierungsfirma",
  LOCATION: "Standort",
  SHOWREMAINING: "Verbleibende Tage anzeigen",
  FREQUENCY: "Wartungs-/Kalibrierungsintervall",
  STARTHOUR: "Startzeit",
  ENDHOUR: "Endzeit",
  ENDDATE: "Enddatum",
  STARTDATE: "Startdatum",
  TITLE: "Titel",
  TITLE1: "Erster Titel",
  TITLE2: "Zweiter Titel",
  SUBTITLE: "Untertitel",
  EMAIL: "E-Mail",
  YOUTUBE: "YouTube-Link",
  GMAP: "Google Maps",
  URL: "Link",
  TEXT2: "Zweiter Text",
  TEXT1: "Text",
  TEXT: "Text",
  BUTTONTEXT: "Button-Text",
  NOPERIOD: "Kein Zeitraum",
  LAST_30_DAYS: "Letzte 30 Tage",
  LAST_90_DAYS: "Letzte 90 Tage",
  LAST_180_DAYS: "Letzte 180 Tage",
  SELECTED_TIME: "Alle Zeiten",
  OWNER: "Eigentümer",
  VETADDRESS: "Tierarztadresse",
  VETPHONE: "Tierarzttelefon",
  VETEMAIL: "Tierarzt E-Mail",
  VETNAME: "Tierarztname",
  GOOGLELINK: "Google Link",
  VIDEOLIST: "YouTube-Playlist",
  IOSLINK: "App Store Link",
  HUAWEILINK: "Huawei Gallery Link",
  COUPONDISCOUNT: "Rabattgutschein",
  COUPONTITLE: "Gutschein Titel",
  COUPONCODE: "Gutscheincode",
  PRODUCT: "Produkt",
  WIFINAME: "WLAN-Name",
  CONTENTLIST: "Inhaltsliste",
  CONTENTIMAGES: "Inhaltsbilder",
  PASSWORD: "Passwort",
  USER: "Benutzer",
  BTNTEXT: "Button-Text",
  TEXT: "Text",
  FILE: "Datei",
  ["AD SOYAD"]: "Vor- und Nachname",
  BÖLÜMÜ: "Abteilung",
  GÖREVI: "Position",
  TELEFONU: "Telefon",
  ["KAN GRUBU"]: "Blutgruppe",
  ["İŞE GIRIŞ TARIHI"]: "Eintrittsdatum",
  ["SICIL NO"]: "Mitarbeiternummer",
  RESPONSIBLESTAFFPHONE: "Verantwortlicher Mitarbeiter Telefon",
  CITY: "Stadt und Landkreis",
  FAX: "Fax",
  ZIP: "Postleitzahl",
  ZIPCODE: "Postleitzahl",
  FAXCOUNTRYCODE: "Fax Ländervorwahl",
  MESSAGE: "Nachricht",
  SSID: "SSID",
  ISHIDDEN: "Versteckt",
  NETWORKTYPE: "Netzwerktyp",
  PW: "Passwort",
  SECURITY: "Sicherheit",
  TOPICON: "Oberes Icon",
  MIDDLEICON: "Mittleres Icon",
  GOOGLE: "Google Play Link",
  APPLE: "App Store Link",
  HUAWEI: "Huawei Gallery Link",
  COUNTRYCODE: "Ländervorwahl",
  ISWIFIHIDDEN: "WLAN-Sichtbarkeit",
  NEW: "Neu!",
  NO_TITLE: "Kein Titel",
  ACTIVE: "Aktiv",
  active: "Aktiv",
  PASSIVE: "Passiv",
  passive: "Passiv",
  status: "Status",
  UNIQUE_SCAN: "Einzigartiger Scan",
  DOWNLOAD: "Downloads",
  SCAN: "Gesamt Scans",
  PRINT: "Drucken",
  VIEW: "Anzeigen",
  ISTATISTICS: "Aktivieren Sie die Statistiken, um detaillierte Informationen zu erhalten.",
  DELETE: "Löschen",
  ACTIVE: "Sie können Ihren QR-Code jederzeit stoppen oder aktivieren, indem Sie ihn auf 'Passiv' oder 'Aktiv' setzen.",
  RENAME: "Umbenennen",
  SEE_PACKAGE: "Paketdetails",
  CANCEL: "Abbrechen",
  LIVE: "Online",
  OK: "Okay",
  ARE_YOU_SURE: "Sind Sie sicher?",
  ERROR: "Fehler",
  ATTENTION: "Achtung!",
  SUCCESS: "Vorgang erfolgreich!",
  DOWNLOAD_ERROR: "Fehler beim Herunterladen",
  CLOSE: "Schließen",
  SORT_BY: "Sortieren nach",
  MOST_RECENT: "Neueste",
  LEAST_RECENT: "Älteste",
  RECENTLY_UPDATE: "Zuletzt aktualisiert",
  LEAST_SCANNED: "Wenigste gescannt",
  MOST_SCANNED: "Meiste gescannt",
  SEARCH: "Suche",
  UPLOAD: "Datei hochladen",
  BACK: "Zurück",
  NEW_FOLDER: "Neuer Ordner",
  IMAGE_QUALITY: "QR-Code-Lesequalität",
  LOW: "Niedrig",
  MEDIUM: "Mittel",
  GOOD: "Gut",
  HIGH: "Hoch",
  BACK_TO_DEFAULTS: "Zurück zum klassischen QR-Code",
  SAVE_QR_CODE: "QR-Code speichern",
  SAVE: "Speichern",
  RESET: "Zurücksetzen",
  SHORT: "Kurz",
  URL: "Link",
  SEND: "Senden",
  SCAN_QR: "QR-Code testen",
  SAVED_DESIGNS: "Als Vorlage speichern",
  LOGOS: "Logos",
  SHOW_ALL: "Alle anzeigen",
  ANIMATION: "Animation",
  STATIC: "Statisch",
  EYE_BALL: "Innenreferenz",
  BODY: "Körperformen",
  EYE_FRAME: "Außenreferenz",
  FRAME: "Rahmen",
  TEMPLATES: "QR-Code-Vorlagen",
  ADVENCED_LOGO_SETTING: "Erweiterte Logo-Einstellungen",
  GO_BACK: "Zurück",
  SELECT: "Auswählen",
  SINGLE_COLOR: "Einfarbig",
  CUSTOMIZE_INDIVIDUALLY: "Individuell anpassen",
  CHANGE_REFERANCE_POINT: "Referenzpunkte ändern",
  NEED_HELP: "Brauchen Sie Hilfe?",
  FRAME_SETTINGS: "Rahmeneinstellungen",
  DEFAULT_LANGUAGE: "Standardsprache",
  DELETE_ALL: "Alle löschen",
  ACTIVATE_ALL: "Alle aktivieren",
  DE_ACTIVATE_ALL: "Alle deaktivieren",
  OPTION: "Optionen",
  DISAGREE: "Auf der Seite bleiben",
  AGREE: "Zustimmen",
  FIRSTSECTIONTITLE: "Bereichsüberschrift",

  field_name: "Feldname",
  field_value: "Feldwert",
  field_type: "Feldtyp",
  DYNAMIC_FIELDS: "Dynamische Felder",
  DYNAMIC_FIELDS_DESC: "Ihr dynamisches Feld ist leer. Bitte klicken Sie auf die Schaltfläche unten, um ein neues Feld hinzuzufügen.",

  you_have_unsaved_changes: "Sie haben nicht gespeicherte Änderungen",
  you_have_unsaved_changes_desc: "Möchten Sie die Seite wirklich verlassen?",
  approve_comment: "Kommentar genehmigen",
  disapprove_comment: "Kommentar ablehnen",
  warning: "Warnung!",
  add_new_languages: "Neue Sprache hinzufügen",
  get_more_information_about: "Mehr Informationen zu den Spracheinstellungen",
  copy_content_from_description: "Der Inhalt der ausgewählten Sprache wird auf neue Sprachen angewendet",
  copy_content_from: "Inhalt aus zu kopierender Sprache",
  you_are_about_to_delete_the_category: "Sie sind dabei, die Kategorie zu löschen",
  are_you_sure_you_want_to_continue: "Wenn Sie fortfahren, verlieren Sie auch die Daten dieser Kategorie zusammen mit der gewählten PDF. Möchten Sie fortfahren?",
  you_at_least_need_to_have_1_category: "Sie müssen mindestens 1 Kategorie haben",
  dark_mode: "Dunkelmodus",
  pdf_is_processing: "PDF wird verarbeitet...",
  image: "Bild",
  browse_my_media: "Meine Medien durchsuchen",
  my_media: "Meine Medien",
  used: "Verwendet",
  my_media_subtext: "Verwalten Sie Ihre Medien",
  my_domains: "Meine Domains",
  my_domains_subtext: "Verwalten Sie Ihre Domains",
  default_domain: "STANDARD-DOMAIN",
  not_verify_domain: "UNBESTÄTIGTE DOMAIN",
  verify_domain: "DOMAIN BESTÄTIGEN",
  make_deafult_domain: "ZUM STANDARD MACHEN",
  default_status: "Standardstatus",
  domain_add: "DOMAIN HINZUFÜGEN",
  animation: "Animation",
  background_color: "Hintergrundfarbe",
  choose_design: "Design auswählen",
  select_from_my_media: "Aus meinen Medien auswählen",
  select_from_animation: "Aus Animationen auswählen",
  age_restriction: "Für Ihre Anwendung können Sie eine Altersbeschränkung einführen, um die Sicherheit und das Nutzererlebnis unserer Benutzer zu gewährleisten. Um auf unsere App zuzugreifen, müssen die Nutzer ihr Alter bestätigen. Dies bedeutet, dass unsere App Inhalte für bestimmte Altersgruppen bereitstellt und Funktionen für Erwachsene einschränkt.",
  age_restriction_text: "Altersbeschränkung",
  age_restriction_tooltip: "Warnung für Nutzer unter einem bestimmten Alter",
  analytics: "Analysen",
  expiration_date: "Ablaufdatum",
  device: "Gerät",
  personal_info: "Persönliche Informationen",
  preparing_template: "Vorlage wird vorbereitet",
  RESERVATIONEMAIL: "Reservierungs-E-Mail",
  locked: "Gesperrt",
  RESTORE: "Wiederherstellen",
  current_password: "Aktuelles Passwort",
  new_password: "Neues Passwort",
  verify_password: "Passwort bestätigen",
  time_limit: "Zeitbegrenzung",
  pdf_is_processing: "PDF wird verarbeitet",
  field_required: "Dieses Feld ist erforderlich",
  start_typing: "Beginnen Sie zu tippen...",
  processing_pdf: "PDF-Datei wird verarbeitet",
  general_settings: "Allgemeine Einstellungen",
  REMINDER: "Erinnerung",
  WEBURL: "Website",
    NONE: "Keine",
  MINUTES: "Minuten übrig",
  HOURS: "Stunden übrig",
  DAYS: "Tage übrig",
  WEEKS: "Wochen übrig",
  COMPLETE: "Abschließen",
  type_your_first_name: "Geben Sie Ihren Vornamen ein",
  type_your_last_name: "Geben Sie Ihren Nachnamen ein",
  type_your_address: "Geben Sie Ihre Adresse ein",
  your_company_info: "Ihre Firmendaten",
  order_summary: "Bestellübersicht",
  order_desc: "Zusammenfassung der ausgewählten Paketdetails.",
  annual: "Jährlich",
  subtotal: "Zwischensumme",
  tax: "Steuer",
  coupon_discount: "Rabattgutschein",
  total: "Gesamtbetrag",
  package_price: "Paketpreis",
  package_discount: "Paket-Rabatt",
  type_your_discount_code: "Geben Sie Ihren Rabattcode ein",
  coupon_code: "Rabattcode",
  use_coupon_code: "Code verwenden",
  cancel_coupon: "Code stornieren",
  i_agree_to: "Ich habe die Vereinbarung gelesen und stimme zu",
  go_back: "ZURÜCK",
  month: "Monat",
  year: "Jahr",
  day: "Tag",
  creditcard_number: "Kreditkartennummer",
  creditcard_name: "Karteninhaber",
  creditcard_expiration_date: "Ablaufdatum",
  creditcard_cvv: "CVC",
  terms_of_use: "Nutzungsbedingungen",
  current_plan: "Aktueller Plan",
  active_package: "Aktives Paket",
  started_at: "Begonnen am",
  ends_at: "Endet am",
  remaining_time: "Verbleibende Zeit",
  package_content: "Paketinhalt",
  newspaper: "Zeitung",
  campaign_start: "Kampagnenbeginn",
  campaign: "Druckinformation",
  campaign_end: "Kampagnenende",
  start_date: "Startdatum",
  end_date: "Enddatum",
  are_you_sure: "Bist du sicher?",
  campaign_info: "Kampagneninformationen",
  print_style: "Druckstil",
  print_amount: "Druckmenge",
  advanced_statistics: "Erweiterte Statistiken",
  advanced_statistics_desc: "Erweiterte Statistiken sind nicht aktiv. Möchten Sie sie aktivieren?",
  change_range: "Filter",
  select_start_and_end_dates: "Wählen Sie Start- und Enddaten aus",
  language_settings: "Spracheinstellungen",
  pdf_info: "Wenn diese Option aktiviert ist, sehen Benutzer keine Seite und werden direkt zur PDF-Datei weitergeleitet.",
  pdf_download: "Sie müssen eine PDF hochladen, um diese Funktion zu aktivieren.",
  general_settings_link: "Klicken Sie hier, um mehr Informationen zu erhalten.",
  email_address_to_be_sent: "E-Mail-Adresse, an die das Kontaktformular gesendet wird",
  general_settings_desc: "Der QR-Code-Titel wird nur Ihnen angezeigt, um ihn von anderen QR-Codes zu unterscheiden. Benutzer werden ihn nicht sehen, und die Änderung des Titels hat keine Auswirkungen auf die Seite.",
  submit: "Absenden",
  invalid_email: "Ungültige E-Mail",
  new_qr_code: "Neuen QR-Code erstellen",
  days_remaining: "VERBLEIBEND:",
  days: "TAG",
  the_last_day_of_your_package: "Der letzte Tag Ihres Pakets",
  full_name: "Vollständiger Name",
  phone: "Telefon",
  email: "E-Mail",
  text: "Text",
  link: "Link",
  desc_message: "Bitte eine Beschreibung eingeben...",
  how_many_qrcodes_you_need: "Wie viele QR-Codes benötigen Sie?",
  request_custom_package: "Spezialpaket anfordern",
  communication: "Kommunikation",
  account_privacy: "Kontolöschung",
  deactivate_your_account: "Deaktivieren Sie Ihr Konto",
  default_language: "Dies wird Ihre Standardsprache sein",
  save_changes: "Änderungen speichern",
  cancel: "Abbrechen",
  avatar: "Profilbild",
  update_your_personal_information: "Aktualisieren Sie Ihre persönlichen Daten",
  allowed_file_types: "Erlaubte Dateitypen",
  change_password: "Passwort ändern",
  account_holder: "Kontoinhaber",
  account_information: "Kontoinformationen",
  FIRSTBTNTEXT: "Erster Button",
  SECONDBTNTEXT: "Zweiter Button",
  request_price: "Spezialangebot anfordern",
  make_request: "ANFRAGE STELLEN",
  gender: "Geschlecht",
  male: "Männlich",
  female: "Weiblich",
  cell_phone: "Mobiltelefon",
  invalid_phone: "Ungültige Telefonnummer",
  invalid_mail: "Ungültige E-Mail-Adresse",
  invalid_website: "Ungültige Website-Adresse",
  invalid_name: "Ungültiger Name",
  dinamik_qr_kod_fiyatları: "Dynamische QR-Code-Preise",
  restaurant_menü_qr_kod_fiyatları: "Restaurantmenü QR-Code-Preise",
  note: "Hinweis",
  user_profile: "Benutzerprofil",
  sign_out: "Abmelden",
  signing_out: "Abmelden...",
  account_settings_and_more: "Kontoeinstellungen und mehr",
  my_profile: "Mein Profil",
  active_subscriptions: "Aktive Abonnements",
  active_subscriptions_subtext: "Meine aktiven Abonnements",
  SELECT_FROM_THE_LIST: "Wählen Sie den Kartentyp aus der Liste",
  package_name: "Paketname",
  device_list: "Geräteliste",
  details: "Details",
  detailed_chart: "Detailliertes Diagramm",
  browser_list: "Browserliste",
  city_list: "Städte Liste",
  country_list: "Länder Liste",
  os_list: "Betriebssysteme",
  age_profile: "Altersprofil",
  ip_list: "IP-Liste",
  gender_profile: "Geschlechtsprofil",
  device_resolution_profile: "Geräteauflösungsprofil",
  last_day: "Tägliche",
  last_week: "Wöchentliche",
  last_month: "Monatlich",
  last_year: "Jährlich",
  page_view: "Seitenansicht",
  unique_visitor: "Eindeutiger Besucher",
  total_view: "Gesamtansicht",
  total_visit: "Gesamtbesuch",
  delete_staticstics: "Möchten Sie die Statistikdaten löschen?",
  avarage_duration_visitors_stay: "Durchschnittliche Aufenthaltsdauer der Besucher",
  total_number: "Gesamtstatistiken",
  sorry_im_leaving: "Es tut mir leid, ich verlasse die Seite!",
  sorry_im_leaving_desc: "Sie sind dabei, Ihr Konto zu löschen. Wenn Sie fortfahren, werden Ihre Daten dauerhaft gelöscht und können nicht wiederhergestellt werden. Möchten Sie wirklich fortfahren?",
  last_30_days: "Letzte 30 Tage",
  loading: "Wird geladen",
  klasor_name: "Ordnername",
  delete_comment: "Kommentar löschen",
  are_you_sure_you_want_to_delete_this_comment: "Sind Sie sicher, dass Sie diesen Kommentar löschen möchten?",
  if_you_delete_this_comment: "Wenn Sie diesen Kommentar löschen, können Sie ihn später nicht wiederherstellen.",
  CREATE: "Erstellen",
  Upgrade: "Upgrade",
  no_content: "Erstellen Sie einen 'NEUEN ORDNER' oder laden Sie eine 'DATEI' hoch.",
  size: "Größe",
  scan: "Scannen",
  limit: "Limit",
  subject: "Betreff",
  message: "Nachricht",
  qrcode: "QR-Code",
  allow_this_to_be_shared: "Ich erlaube, dass dies mit anderen Benutzern geteilt wird",
  package_limit: "Paketlimit",
  rows_per_page: "Zeilen pro Seite",
  coupon_discount: "Rabattcoupon",
  rows_per_page: "Zeilen pro Seite",
  your_subscription_has_expired: "Ihr Abonnement ist abgelaufen",
  two_ways_to_keep_using: "Zwei Möglichkeiten, um weiter zu verwenden",
  contact_us: "Kontaktieren Sie uns",
  upgrade_now: "Jetzt upgraden",
  or: "ODER",
  no_data: "Keine Daten zum Anzeigen",
  list_is_empty: "Liste ist leer",
  recaptcha_error: "Bitte beweisen Sie, dass Sie kein Roboter sind",
  if_you_delete_this_qr_code: "Wenn Sie diesen QR-Code löschen, gehen auch die zugehörigen Daten verloren.",
   delete_all_childs_alert_text: ({ named }) =>
    `Diese nicht rückgängig zu machende Aktion wird ${named(
      "amount"
    )} QR-Codes unter dem Master löschen. Bitte stellen Sie sicher, dass Sie diese QR-Codes löschen möchten, bevor Sie fortfahren.`,
  qr_codes_that_are_not_recovered: "QR-Codes, die innerhalb von 20 Tagen nicht wiederhergestellt werden, werden endgültig gelöscht.",
  changing_URL_will_create_a_new_qr_code:
    "Das Ändern der URL wird einen neuen QR-Code erstellen, und wenn Sie fortfahren, wird die gedruckte Version der alten URL nicht mehr zugänglich sein.",
  attention: "Wichtig! Achtung!",
  valid_in_dynamic: "Gültig in dynamisch",
  you_can_not_add_more_than_connections: ({ named }) => `Sie können nicht mehr als ${named("amount")} Verbindungen hinzufügen.`,
  if_you_delete_this_language_you_will_lose_all_the: "Wenn Sie diese Sprache löschen, gehen alle damit verbundenen Daten verloren. Sind Sie sicher, dass Sie fortfahren möchten?",
  loading_items: "Daten werden geladen",
  your_profile_data_has_been_successfuly_updated: "Ihre Profildaten wurden erfolgreich aktualisiert!",
  your_package_will_be_processed_after_your_payment_has_been_received: "Ihr Paket wird bearbeitet, sobald Ihre Zahlung eingegangen ist.",
  verify_password_does_not_match: "Das Passwort stimmt nicht überein, bitte überprüfen Sie es",
  please_fill_all_fields: "Bitte füllen Sie alle Felder aus",
  text_must_be_less_than_2500_characters: "Der Text darf nicht mehr als 2500 Zeichen enthalten",
  title_must_be_less_than_80_characters: "Der Titel darf nicht mehr als 80 Zeichen enthalten",
  must_be_less_than_80_characters: "darf nicht mehr als 80 Zeichen enthalten",
  must_be_less_than_200_characters: "darf nicht mehr als 200 Zeichen enthalten",
  must_be_less_than_100_characters: "darf nicht mehr als 100 Zeichen enthalten",
  you_need_to_chose_a_logo_or_toggle_off_in_media_settings: "Sie können ein Bild von Ihrem PC auswählen und durch Klicken auf den roten Button das Bild auf der Karte anzeigen lassen. In den 'Medien'-Einstellungen müssen Sie entweder ein Bild auswählen oder das Foto-Tab deaktivieren.",
  is_required: "ist erforderlich",
  btnText: "Button-Text",
  title_is_required: "Titel ist erforderlich",
  this_is_required: "Dies ist erforderlich",
  phone_is_required: "Telefonnummer ist erforderlich",
  this_url_is_invalid: "Diese URL ist ungültig",
  please_input_a_valid_phone_number: "Bitte geben Sie eine gültige Telefonnummer ein",
  you_need_to_chose_start_or_end_date: "Sie müssen ein Start- oder Enddatum auswählen",
  you_need_to_fill_the_required_fields: "Sie müssen die erforderlichen Felder ausfüllen",
  selected_file: "PDF auswählen",
  no_file: "Keine Datei",
  no_link: "Link hinzufügen",
  add_more_button_text: "Neues Feld hinzufügen",
  go_to_main_page: "Zur Startseite zurückkehren",
  new_address: "Neue Adresse",
  information: "Information",
  description_for_the_address: "Adressbeschreibung",
  you_can_not_add_more_than_10_emergency_number: "Sie können nicht mehr als 10 Notrufnummern hinzufügen",
  verify_password_does_not_match: "Das Passwort stimmt nicht überein, bitte überprüfen Sie es",
  please_fill_all_fields: "Bitte füllen Sie alle Felder aus",
  text_must_be_less_than_2500_characters: "Der Text darf nicht mehr als 2500 Zeichen enthalten",
  title_must_be_less_than_80_characters: "Der Titel darf nicht mehr als 80 Zeichen enthalten",
  must_be_less_than_200_characters: "Der Text darf nicht mehr als 200 Zeichen enthalten",
  must_be_less_than_500_characters: "Der Text darf nicht mehr als 500 Zeichen enthalten",
  you_need_to_chose_a_logo_or_toggle_off_in_media_settings: "Sie können ein Bild von Ihrem PC auswählen und durch Klicken auf den roten Button das Bild auf der Karte anzeigen lassen. In den 'Medien'-Einstellungen müssen Sie entweder ein Bild auswählen oder das Foto-Tab deaktivieren.",
  is_required: "ist erforderlich",
  this_is_required: "Dieses Feld ist erforderlich",
  invalid_url: "Ungültige URL",
  continue: "JETZT ERSTELLEN",
  direct_download: "Direkter Download (Nach dem Scannen des QR-Codes wird die Datei direkt auf das Telefon des Nutzers heruntergeladen)",
  REMAININGCOUNTERVISIBLEFORLASTAMOUNTDAYS: "Der Zähler wird für die letzten __ Tage angezeigt",
  select_pdf_for_each_language: "Klicken Sie auf die 'PDF auswählen'-Schaltfläche. Erstellen Sie einen neuen Ordner und laden Sie Ihre Datei hoch. Klicken Sie dann auf die hochgeladene Datei, um das PDF zu verarbeiten. Sie müssen für jede Sprache eine PDF-Datei hinzufügen. Diese Sprachen haben keine PDF-Datei: ",
  missing_locations_for_languages: "Bitte verwenden Sie Ihre ADRESSE nicht per Kopieren und Einfügen! Um eine korrekte ADRESSE auszuwählen, suchen Sie bitte nach Ihrer Adresse und wählen Sie sie aus dem angezeigten Menü aus!",
  drag_drop: "Ziehen und ablegen, um die Reihenfolge zu ändern",
  change_order: "Reihenfolge ändern",
  caption: "Untertitel",
  image: "Bild",
  add_new: "Neu hinzufügen",
  delete_selected: "Ausgewählte löschen",
  your_own: "Ihr eigenes",
  logo: "Logo",
  add: "Hinzufügen",
  qrcode_static_subtext: "Wählen Sie, um Ihren statischen QR-Code zu erstellen.",
  type_something_here: "Geben Sie hier eine Beschreibung ein",
  start_program: "Start",
  end_program: "Ende",
  billed_annually: "Jährliche Abrechnung",
  no_folder: "Kein Ordner",
  I_WANT_TO_GET_INFORMATION_ABOUT_THE_PRODUCTS: "ICH MÖCHTE INFORMATIONEN ÜBER DIE PRODUKTE ERHALTEN",
  keep_track: "Verfolgen Sie, wie viele Benutzer diesen QR-Code gescannt haben, und vergleichen Sie Ihre Website mit <strong>Erweiterten Statistiken</strong></br></br>Klicken Sie, um zu den <strong>Erweiterten Statistiken</strong> zu gelangen, nachdem Sie das Schloss geöffnet haben, und klicken Sie auf die Schaltfläche in der oberen rechten Ecke.</br></br><img src='https://i.imgur.com/fOk5J81.png' /> ",
  view_user_ratings: "Benutzerbewertungen anzeigen, bearbeiten, löschen",

  not_a_valid_linkedin_link: "Dies ist kein gültiger LinkedIn-Link",
  not_a_valid_facebook_link: "Dies ist kein gültiger Facebook-Link",
  not_a_valid_twitter_link: "Dies ist kein gültiger Twitter-Link",
  not_a_valid_instagram_link: "Dies ist kein gültiger Instagram-Link",
  not_a_valid_whatsapp_link: "Dies ist kein gültiger WhatsApp-Link",
  not_a_valid_youtube_link: "Dies ist kein gültiger YouTube-Link",
  not_a_valid_reddit_link: "Dies ist kein gültiger Reddit-Link",
  not_a_valid_telegram_link: "Dies ist kein gültiger Telegram-Link",
  not_a_valid_snapchat_link: "Dies ist kein gültiger Snapchat-Link",
  not_a_valid_pinterest_link: "Dies ist kein gültiger Pinterest-Link",
  not_a_valid_yelp_link: "Dies ist kein gültiger Yelp-Link",
  not_a_valid_flicker_link: "Dies ist kein gültiger Flicker-Link",
  not_a_valid_vimeo_link: "Dies ist kein gültiger Vimeo-Link",
  not_a_valid_vk_link: "Dies ist kein gültiger VK-Link",
  not_a_valid_soundcloud_link: "Dies ist kein gültiger SoundCloud-Link",
  not_a_valid_github_link: "Dies ist kein gültiger GitHub-Link",
  not_a_valid_tumblr_link: "Dies ist kein gültiger Tumblr-Link",
  not_a_valid_skype_link: "Dies ist kein gültiger Skype-Link",
  
  give_us_feedback: "Bitte geben Sie uns Ihr Feedback",
  please_do_let_us_know: "Lassen Sie es uns bitte wissen:",
  your_feedback_is_important_to_us: "Ihr Feedback ist uns wichtig!",
  if_you_would_like_to_let_us_know: "Wenn Sie uns mitteilen möchten, wie wir unsere Produkte oder Dienstleistungen verbessern können oder ein Kompliment teilen möchten, würden wir uns freuen, von Ihnen zu hören!",
  how_you_use_our_products: "Wie verwenden Sie unsere Produkte?",
  how_we_can_improve_our_products_or_service: "Wie können wir unsere Produkte oder Dienstleistungen verbessern?",
  which_conferences_you_think_we_should_attend: "Welche Konferenzen sollten wir Ihrer Meinung nach besuchen?",
  any_publications_citing_the_use_of_our_products: "Alle Veröffentlichungen, die auf die Verwendung unserer Produkte verweisen",
  we_look_forward_to_hearing_from_you: "Wir freuen uns darauf, von Ihnen zu hören!",
  feedback_form: "Feedback-Formular",
  please_feed: "Bitte teilen Sie uns Ihr Feedback mit",
  feed_back_share: "Mit uns teilen!",
  thank_you: "Vielen Dank!",
  rate_us: "Bewerten Sie uns!",
  we_have_recieved_your_feedback: "Ihre Nachricht ist bei uns eingegangen!",
  i_allow_for_this_message_to_be_shared_the_other_users: "Ich erlaube es, diese Nachricht mit anderen Nutzern zu teilen",
  
  if_you_delete_this_file: "Wenn Sie diese Datei löschen, können die QR-Codes, die aktiv auf dieses Medium verweisen, beschädigt werden. Sind Sie sicher, dass Sie fortfahren möchten?",
  if_you_delete_selected_files: "Wenn Sie diese ausgewählten Dateien löschen, können die QR-Codes, die aktiv auf diese Medien verweisen, beschädigt werden. Sind Sie sicher, dass Sie fortfahren möchten?",
  if_you_delete_this_folder: "Wenn Sie diesen Ordner löschen, können die QR-Codes, die aktiv auf dieses Medium verweisen, beschädigt werden. Sind Sie sicher, dass Sie fortfahren möchten?",
  
  you_need_to_select_pdf_for_each_language_missing_in: "Für jede Sprache muss eine PDF hinzugefügt werden. Fehlend:",
  you_can_not_delete_default_language: "Sie können die Standardsprache nicht löschen",
  you_can_not_delete_active_language: "Sie können die aktive Sprache nicht löschen",
  Set_as_a_default: "Als Standard festlegen",
  Delete: "Löschen",
  Activate: "Sprache aktivieren",
  a_new_verification_mail_has_been_sent_to_your_email_address: "Eine neue Bestätigungs-E-Mail wurde an Ihre E-Mail-Adresse gesendet.",
  email_verification: "E-Mail-Verifizierung",
  did_not_recieve_an_email: "Haben Sie keine E-Mail erhalten?",
  re_send_verification_mail: "Bestätigungs-E-Mail erneut senden",
  just_for_you_to_join_us_one_step_left: "Nur noch ein Schritt, um uns beizutreten",
  an_activation_email_has_been_sent: "Eine Aktivierungs-E-Mail wurde an Ihre E-Mail-Adresse gesendet. Klicken Sie auf den Link, um Ihr Konto zu aktivieren.",
  locks_your_app_and_asks_users_to_enter_this_password: "Um auf Ihre App zuzugreifen, wird aus Sicherheitsgründen ein Passwortsystem verwendet. Benutzer müssen ein vorher festgelegtes Passwort korrekt eingeben, um die App zu besuchen oder auf private Inhalte zuzugreifen. Dieses Passwort dient der Benutzeridentifikation und verhindert unbefugten Zugriff, um die Sicherheit der Benutzerdaten zu gewährleisten.",
  paste_given_code_if_you_would_like_to_track_your_statictics: "Wenn Sie Ihre Statistiken detailliert verfolgen möchten, fügen Sie den Code von Yandex Metrika oder Google Analytics hier ein.",
  if_you_fill_out_the_below_fiels_qr_code_will_be_unavailable_before_start_date_and_after_end_date: "Wenn Sie die folgenden Felder ausfüllen, wird der QR-Code vor dem Startdatum und nach dem Enddatum nicht verfügbar sein.",
  the_content_will_only_be_avialable_to_the_selected_deviceList: "Der Inhalt ist nur für die ausgewählten Geräte verfügbar.",
  this_must_be_less_than_5_characters: "Dies muss weniger als 5 Zeichen lang sein",
  click_here_to_save_qrcode: "<strong>NICHT GESPEICHERT!</strong> Gehen Sie zur Bearbeitungsseite und speichern Sie ihn!",
  type_changer_disclaimer: ({ named }) =>
    `Sie sind dabei, von einem aktiven <strong>${named("currentType")}</strong> QR-Code zu einem anderen zu wechseln. Wenn Sie fortfahren, werden alle Daten für diesen QR-Code gelöscht und Sie werden auf die entsprechende Seite weitergeleitet, um die erforderlichen Felder auszufüllen. Bitte beachten Sie, dass dieser Vorgang nicht rückgängig gemacht werden kann.`,
  btnText: "Button-Text",
  your_short_url_will_look_like_this: "Ihre Kurz-URL wird so aussehen (wenn es nicht Ihre eigene Domain ist): q-r.cc/adc256",
  please_input_a_valid_phone_number: "Bitte geben Sie eine gültige Telefonnummer ein",
  qr_code_warning_text: "Hinweis: Wenn Sie Ihre Domain oder Werte ändern, wird der Inhalt des QR-Codes verändert. Wenn der Code bereits gedruckt wurde, wird der QR-Code nicht mehr funktionieren.",
  your_profile_photo_has_been_successfuly_updated: "Ihr Profilbild wurde erfolgreich aktualisiert!",
  your_profile_photo_has_been_successfuly_removed: "Ihr Profilbild wurde erfolgreich entfernt!",
  you_at_least_need_to_have_1_address: "Sie müssen mindestens eine Adresse haben!",

  pricing_guide: `
  <div style="padding:30px;background:#ffffff">
            <h1 class="text-center">Preise</h1>
                        <ul>
              <li>Wir verfolgen eine transparente und faire Preisgestaltung, um Lösungen anzubieten, die den Bedürfnissen unserer Kunden entsprechen. Für unsere QR-Code-Lösungen bieten wir verschiedene Pakete an: Starter Paket, Advanced Paket und Business Paket. Darüber hinaus bieten wir spezielle Angebote für Kunden mit besonderen Bedürfnissen an.</li>

                <li>Unsere Pakete werden nach Anzahl der Codes verkauft, und jedes Paket bietet bestimmte Funktionen und Service-Level. Die Auswahl zwischen den Paketen hängt von den Bedürfnissen, dem Budget und den Anwendungsszenarien unserer Kunden ab.</li>

                <li>Das Starter Paket ist ein hervorragender Einstiegspunkt für kleine Unternehmen und private Nutzer. Das Advanced Paket bietet eine breitere Palette an Funktionen für mittelständische Unternehmen und Nutzer mit höheren Anforderungen. Das Business Paket ist für große Unternehmenskunden konzipiert, die komplexere Anforderungen haben.</li>

                <li>Unsere Sonderangebote bieten maßgeschneiderte Lösungen und Pakete für bestimmte Projekte oder einzigartige Anforderungen, die außerhalb der Standardcode-Anzahlen liegen. Wir bieten flexible Preisoptionen, um unseren Kunden zu helfen, die beste Lösung basierend auf ihren spezifischen Bedürfnissen zu finden.</li>

                <li>Für weitere Informationen zu den Preisdetails und Paketfunktionen kontaktieren Sie uns bitte.</li>
              </ul>
            <p class="text-end">QR Code Matic</p>
          </div>
  `,
  how_it_works: "Wie es funktioniert",
  how_it_works_button_text: "Wie es funktioniert",
  your_subscription_expired: "Ihr Paket ist abgelaufen. Sie können kostenlos statische QR-Codes erstellen.",
  qrcode_will_be_deleted_in_24: "Dieser QR-Code wird in 1 Tag gelöscht. Klicken Sie hier, um den Grund zu erfahren",

  //domain list
  manage_your_own_domains: "Verwalte deine eigenen Domains",
  domain_list: "Domain Liste",
  domain_name: "Domain Name",
  short_link: "Kurzlink",
  options: "Optionen",
  delete: "Löschen",
  domain_delete: "Domain Entfernen",
  domain_delete_desc: "Möchten Sie diese Domain löschen?",
  domain_delete_desc_2: "Sind Sie sicher, dass Sie fortfahren möchten?",
  verified_status: "Verifizierungsstatus",
  how_to_setup: "Wie man es einrichtet",
  how_to_setup_desc: "Um Ihre eigene Domain als URL-Shortener zu aktivieren, müssen Sie einen Subdomain-Eintrag auf Ihrer Domain einrichten. Die Subdomain sollte mit einem CNAME-Eintrag auf unsere Domain q-r.cc verweisen. Stellen Sie sicher, dass Sie zuerst Ihre Domain eingerichtet haben, da es bis zu 24 Stunden dauern kann, bis unser System die Änderungen an Ihrer Subdomain erkennt.",
  what_is_a_short_URL: "Was ist eine Kurz-URL?",
  what_is_a_short_URL_desc: "Dynamische Codes verwenden eine kurze URL, die auf die gewünschte Zielseite weiterleitet. Dies ist notwendig, um Tracking-Daten zu sammeln und Codes zu bearbeiten. Sie können diese Domain zum Beispiel so ändern:",
  
  create_template_button_text: ({ named }) =>
    `<span class="font-weight-bold mr-1">Design</span><span class="font-weight-light">Erstellen</span>`,
  queue_processing_title: ({ named }) => `${named("type")} Vorgang läuft!`,
  queue_done_title: ({ named }) => `${named("type")} Vorgang abgeschlossen!`,
  queue_done_text: ({ named }) =>
    `${named("type")} Master-Staffelauftrag ist abgeschlossen. Jetzt können Sie Änderungen an Ihren Master QR-Codes und den zugehörigen untergeordneten QR-Codes vornehmen.`,
  queue_processing_text: ({ named }) =>
    `${named("type")} Ihrer Anfrage wurde in die Warteschlange gestellt. Sie müssen warten, bis Ihre Anfrage abgeschlossen ist. Sie erhalten eine E-Mail, sobald der Vorgang abgeschlossen ist.`,
  waiting_for_process: "Warten auf die Fertigstellung Ihrer Anfrage...",
  select_all: "Alle Auswählen",
  deselect_all: "Auswahl Aufheben",
  add_more_child_from_previous_qrcodes: "+ Weitere QR-Codes unter diesem hinzufügen",
  add_more_child_from_previous_qrcodes_tooltip:
    "Sie können weitere QR-Codes unter diesen QR-Code hinzufügen oder verschieben, um diese schnell und in großen Mengen zu aktualisieren.",
  convert_to_master: "Als Master umwandeln",
  convert_to_master_tooltip: "Wandeln Sie diesen QR-Code in einen Master um, um weitere QR-Codes hinzuzufügen oder zu verschieben.",
  add_more_child_dialog_title: "Fügen Sie vorhandene QR-Codes unter diesem Master hinzu",
  add_more_child_dialog_button_text: "Übertragen",
  add_more_child_is_successful: "Vorgang erfolgreich",
  add_more_child_dialog_p1: () => `Dieses Menü ermöglicht es Ihnen, die zuvor erstellten QR-Codes in einen Master QR-Code aufzunehmen, um eine <strong>Batch-Verarbeitung</strong> durchzuführen. So können Sie die ausgewählten QR-Codes gemeinsam bearbeiten.`,
  add_more_child_dialog_p2: ({ named }) => `Die QR-Codes, die Sie aus der folgenden Liste auswählen, werden unter dem Master QR-Code mit dem Titel <strong>${named("title")}</strong> / <strong>${named("vars")}</strong> hinzugefügt. Anschließend können Sie die Massenbearbeitung dieser QR-Codes über <strong>${named("title")}</strong> durchführen.`,
  add_more_child_dialog_p3: () => `Die Warnsymbole neben den QR-Codes in der Liste bedeuten folgendes:
  <ul>
    <li><span style="background-color:#1abcb5;padding:2px 5px;border-radius:2px;color:#ffffff;">1 untergeordneter QR-Code vorhanden</span> Dieser QR-Code hat untergeordnete QR-Codes. Wenn Sie diese auswählen, werden die untergeordneten QR-Codes zusammen mit dem ausgewählten QR-Code übertragen.</li>
    <li><i class="fa-solid fa-circle-info orange--text mr-2"></i>Dieser QR-Code ist ein Master-Code. Wenn Sie ihn auswählen, verliert er seine Master-Funktion und wird unter den zu bearbeitenden QR-Code verschoben.</li>
  </ul>`,
  are_you_sure_convert_child_to_master: ({ named }) => 
    `Sie sind dabei, den QR-Code mit dem Titel ${named("title")} aus seinem Master zu entfernen und ihn selbst zum Master zu machen. Sind Sie sicher, dass Sie fortfahren möchten?`,
  add_more_child_count: ({ named }) => `${named("count")} untergeordnete QR-Codes vorhanden`,
  gradient_on_eyes: "Gradient auf den Augen",
  update_prices: "Menüpreise aktualisieren",
  confirm_delete_all: "Sind Sie sicher, dass Sie alle diese QR-Codes löschen möchten?",
  unsaved_changes_will_be_lost: "Nicht gespeicherte Änderungen gehen verloren. Sind Sie sicher, dass Sie die Seite verlassen möchten?",
  show_media: "Zeige Medien, deren Links kopiert werden",
  $LOGIN: {
    welcome_to: "Willkommen bei QR Code Matic!",
    just_one_step: "Nur noch ein Schritt, um einen dynamischen oder statischen QR-Code zu erstellen!",
    you_are_almost_done: "Du bist fast fertig!",
    please_choose_appropriate_options: "Bitte wähle die passende Option! Du kannst schnell die Option 'ANDERE' unten oder die Option 'PERSÖNLICHE NUTZUNG' oben auswählen.",
    please_chose_one_of_the_options: "Bitte wähle eine Option aus",
    disclaimer: "Im Testmodus werden KEINE KREDITKARTENDATEN abgefragt. Stelle bitte sicher, dass du das richtige Land auswählst.",
    new_here: "Neu hier?",
    create_an_account: "Konto erstellen",
    password: "Passwort",
    password_re: "Passwort bestätigen",
    forgot_password: "Passwort vergessen",
    sign_in: "Anmelden",
    sign_in_with_google: "Mit Google anmelden",
    sign_in_with_facebook: "Mit Facebook anmelden",
    sign_in_with_twitter: "Mit Twitter anmelden",
    sign_up: "Registrieren",
    subheader: "Fülle die erforderlichen Informationen für die Registrierung aus",
    your_organization: "Branche",
    your_country: "Land",
    fullname: "Vollständiger Name",
    confirm_password: "Passwort erneut eingeben",
    agree_terms: "Ich akzeptiere die Datenschutz- und Nutzungsbedingungen",
    did_you_forget_password: "Hast du dein Passwort vergessen?",
    forgot_password_subheader: "Gib deine E-Mail-Adresse ein, um dein Passwort zurückzusetzen",
    submit: "Absenden",
    cancel: "Abbrechen",
    terms: "Datenschutz- und Nutzungsbedingungen",
    contact_us: "Kontaktieren Sie uns",
    enter_new_password: "Neues Passwort eingeben",
    your_password_has_been_reset: "Dein Passwort wurde zurückgesetzt",
    you_will_be_redirected_to_login: "Du wirst zur Login-Seite weitergeleitet...",
    reset_link_is_sent_to_your_mail: "Der Zurücksetzungslink wurde an deine E-Mail gesendet.",
    check_your_mail_box: "Überprüfe dein Postfach",
    login: "ANMELDEN",
    your_country: "Land auswählen",
    your_language: "Sprache auswählen"
  },
  $TICKET: {
    mail_support: "Support-E-Mail",
    choose_a_file: "Datei auswählen oder hier ablegen...",
    enter_mail: "E-Mail eingeben...",
    mail: "E-Mail",
    subject: "Betreff",
    message: "Nachricht",
    select_topic: "Thema auswählen",
    qenerals_quetion: "Allgemeine Frage",
    billing: "Abrechnung",
    technical_question: "Technische Frage",
    login_issue: "Login-Problem",
    other: "Sonstiges"
  },
  $ORDERS: {
    order_number: "Bestellnummer",
    order_status: "Bestellstatus",
    product_name: "Produktname",
    currency: "Währung",
    order_date: "Bestelldatum",
    active_package: "Aktives Paket",
    started_at: "Beginnzeit",
    expires_at: "Ablaufzeit",
    remaining_days: "Verbleibende Tage",
    actions: "Aktionen",
    order_title: "Bestellungen",
    payment_confirmed: "Bestätigt",
    incorrect_payment: "Fehlerhafte Zahlung",
    awaiting_payment: "Zahlung ausstehend",
    order_canceled: "Storniert",
    cancel_order: "Bestellung stornieren",
    status: "Bestellstatus",
    invalid_package: "Ungültiges Paket",
    payment_type: "Zahlungsmethode",
    account_number: "Kontonummer",
    payment_amount: "Zahlungsbetrag",
    payment_desc: "Zahlungsbeschreibung",
    bank_transfer: "Banküberweisung",
    creditcard: "Kreditkarte"
  },
  $SELECT_SECTOR: {
    personal_use: "Persönliche Nutzung",
    tourism: "Tourismus",
    restaurants: "Restaurants",
    software: "Software",
    photo_video: "Foto und Video",
    cities_culture: "Städte und Kultur",
    event_management: "Veranstaltungsmanagement",
    home_repair: "Haus und Reparatur",
    finance: "Finanzen",
    publisher: "Verlag",
    nonprofit: "Gemeinnützig",
    retail: "Einzelhandel",
    real_estate: "Immobilien",
    gym: "Fitnessstudios und Wellness",
    hotel: "Hotels und Resorts",
    products: "Produkte",
    education: "Bildung",
    goverment: "Regierung",
    doctors: "Ärzte und Gesundheitsdienste",
    agency: "Agenturdienste",
    electronics: "Elektronik",
    paper: "Möbel, Papier und Papierprodukte",
    glass: "Glas, Zement und Ton",
    environment: "Umwelt",
    energy: "Energie",
    foods: "Lebensmittel",
    construction: "Bau",
    business: "Geschäft und Management",
    chemistry: "Chemie, Erdöl, Gummi und Kunststoff",
    culture: "Kultur, Kunst und Design",
    mines: "Bergbau",
    machines: "Maschinen",
    media: "Medien, Kommunikation und Verlagswesen",
    metal: "Metall",
    automotive: "Automobil",
    social: "Soziale Dienste",
    sports: "Sport und Freizeit",
    agriculture: "Landwirtschaft, Jagd und Fischerei",
    textile: "Textilien, Bekleidung, Leder",
    trade: "Handel (Verkauf und Marketing)",
    community: "Gemeinschaft und persönliche Dienstleistungen",
    beverage: "Lebensmittel- und Getränkeindustrie",
    transport: "Transport und Logistik",
    communications: "Kommunikation",
    other: "Sonstiges"
  },
$SUBSCRIPTION: {
    selected_package_name: "Ausgewähltes Paket",
    choose_payment_method: "Zahlungsmethode wählen",
    choose_payment_method_description: "Wählen Sie die Zahlungsmethode, die am besten zu Ihnen passt",
    add_payment_info: "Zahlungsinformationen",
    payment_is_safe: "Zahlungsvorgang ist sicher",
    billing_info: "Rechnungsinformationen",
    complete_payment: "Zahlung abschließen",
    bank_transfer_info: "Banküberweisungsinformationen",
    about_pricing: "Wenn Sie mehr Informationen zu unserer Preisgestaltung benötigen, schauen Sie sich bitte die Details an",
    choose_your_plan: "Wählen Sie Ihren Plan",
    pricing_policy: "Preisrichtlinie",
    private_or_business: "Bitte wählen Sie, ob es sich um einen privaten oder geschäftlichen Kauf handelt",
    company_invoice: "Firmenrechnung",
    individual_invoice: "Privatrechnung",
    select: "KAUFEN",
    discountMessage: ({ named }) => `${named("amount")}$ Rabatt angewendet`,
  },
  validations: {
    field_required: "Dieses Feld ist erforderlich",
    email_required: "E-Mail erforderlich",
    password_required: "Passwort erforderlich",
    agree_terms: "Sie müssen die Bedingungen akzeptieren",
    invalid_zip: "Ungültige Postleitzahl",
    invalid_email: "Ungültige E-Mail",
    invalid_password: "Ungültiges Passwort",
    invalid_youtube: "Ungültiger YouTube-Link",
    not_a_valid_email: "Keine gültige E-Mail",
    not_a_valid_phone_number: "Keine gültige Telefonnummer",
    you_must_accept_the_terms: "Sie müssen die Bedingungen akzeptieren",
    your_password_does_not_match: "Ihr Passwort stimmt nicht überein",
    you_must_enter_valid_email: "Sie müssen eine gültige E-Mail-Adresse eingeben",
    you_must_enter_password: "Sie müssen ein Passwort eingeben",
    you_must_enter_your_name: "Sie müssen Ihren Namen eingeben",
    you_must_choose_country: "Sie müssen ein Land auswählen",
    you_must_choose_organization: "Sie müssen eine Organisation auswählen",
    must_contain_numbers: "Muss mindestens eine Zahl enthalten",
    must_contain_letters: "Muss mindestens einen Buchstaben enthalten",
    short_link_is_required: "Erforderlich",
    short_link_special_char: "Darf nur Zahlen und Buchstaben enthalten",
    short_link_between: "Muss zwischen 3 und 30 Zeichen lang sein",
    less_than: ({ named }) => `Muss weniger als ${named("amount")} Zeichen sein`,
    more_than: ({ named }) => `Muss mindestens ${named("amount")} Zeichen lang sein`,
  },
  authTemplate: {
    confirm: "Bestätigen",
    legal_disclaimer_title: () => `<h1><span>Rechtliche</span> Hinweise</h1>`,
    legal_disclaimer: "Um diese Seite zu besuchen, müssen Sie mindestens 11 Jahre alt sein. Wenn Sie unter 11 Jahre alt sind, haben Sie keinen Zugriff auf diese Seite.",
    login_area_title: () => `<h1><span>Login</span> Bereich</h1>`,
    login_button_text: "Einloggen",
    login_disclaimer: "Der Inhalt, auf den Sie zugreifen möchten, ist verborgen. Geben Sie Ihr Passwort ein, um auf die Seite zuzugreifen.",
  },
  ALERT: {
    alert_msg_1: "Sie sind dabei, statische QR-Code-Daten zu bearbeiten. Dies wird den QR-Code selbst ändern. Änderungen, die Sie vornehmen, gelten nicht für den gedruckten oder verteilten QR-Code. Sind Sie sicher, dass Sie fortfahren möchten?",
  },
  FAQ_MAIN: {
    faqs_and_guides_about_qr_code: "Häufig gestellte Fragen und Anleitungen zu QR-Codes",
    search_for_topics: "Suche nach Themen...",
    faq: "Häufige Fragen",
    the_most_frequently_asked_question: "Die häufigsten Fragen",
    articles_in_this_collection: "Artikel in dieser Sammlung",
    written_by_QR_Code_Matic: "Geschrieben von QR Code Matic",
    setting_up_your_account: "Ihr Konto einrichten",
    why_qr_code: "Warum QR-Code?",
    pricing_and_billing: "Preise und Abrechnung",
    information_about_prices_and_invoices: "Informationen zu Preisen und Rechnungen",
    find_out_everything_you_need_to_know_about_qr_codes_here: "Erfahren Sie hier alles, was Sie über QR-Codes wissen müssen",
    analytic_title: "Verständnis der Datenanalyse: Bedeutung und Anwendungen in Ihrem Unternehmen",
    get_to_know_our_pricing_terms_and_billing: "Erfahren Sie mehr über unsere Preisgestaltung und Bedingungen",
    analytic: "Analytik",
  },
  FAQ: {
    static_dynamic_title: "Was ist der Unterschied zwischen statischen und dynamischen QR-Codes?",
    static_dynamic: `Statische QR-Codes können nach ihrer Erstellung und dem Drucken nicht mehr geändert werden. Wenn der Link nicht mehr funktioniert, können Sie nichts anderes tun, als den QR-Code neu zu drucken. Dynamische QR-Codes hingegen sind bearbeitbar und können in Echtzeit geändert werden. Ein dynamischer QR-Code enthält eine Platzhalter-URL, was es ermöglicht, denselben QR-Code immer wieder zu verwenden. Dies spart Zeit, Aufwand und Kosten.`,

    better_static_dynamic_title: "Welcher ist besser: Statischer oder dynamischer QR-Code?",
    better_static_dynamic: `Wenn Sie eine einmalige Marketingkampagne ohne die Notwendigkeit für spätere Änderungen oder Verfolgung planen, sind statische QR-Codes möglicherweise besser für Sie geeignet. Wenn Sie jedoch mehr Flexibilität, QR-Scan-Analytik, Online-Retargeting, schnellere Scans usw. benötigen, sind dynamische QR-Codes sehr viel empfehlenswerter als statische.`,

    how_change_dynamic_title: "Wie kann ich einen statischen QR-Code in einen dynamischen QR-Code umwandeln?",
    how_change_dynamic: `Sie können einen statischen QR-Code nicht in einen dynamischen QR-Code umwandeln, da statische QR-Codes nach ihrer Erstellung nicht mehr bearbeitet werden können.`,

    how_qrcode_free_title: "Wie kann ich einen kostenlosen QR-Code erstellen?",
    how_qrcode_free: `Gehen Sie zum QR Code Matic QR-Code-Generator und beginnen Sie, Ihren QR-Code kostenlos zu erstellen. Sie können ihn in verschiedenen Formaten herunterladen und nach Wunsch anpassen. Hinweis: Statische QR-Codes sind dauerhaft kostenlos. Für die Erstellung dynamischer QR-Codes müssen Sie jedoch entweder eine 14-tägige kostenlose Testversion starten oder einen Plan erwerben.`,

    what_is_qr_code_title: "Was ist ein QR-Code?",
    what_is_qr_code: `Der Name QR-Code kommt von den ersten Buchstaben des englischen Begriffs "Quick Response". Ein QR-Code ist ein 2D-Barcode, der von der Firma Denso Wave entwickelt wurde und es ermöglicht, hunderte von Zeichen zu verschlüsseln. Standardmäßig besteht er aus schwarzen und weißen Pixeln. Die verschlüsselten Daten können dann mit einem Smartphone oder Tablet schnell entschlüsselt und angezeigt werden.`,

    how_can_i_generate_qr_code_title: "Wie kann ich einen QR-Code erstellen?",
    how_can_i_generate_qr_code: `Der Name QR-Code kommt von den ersten Buchstaben des englischen Begriffs "Quick Response". Ein QR-Code ist ein 2D-Barcode, der von der Firma Denso Wave entwickelt wurde und es ermöglicht, hunderte von Zeichen zu verschlüsseln. Standardmäßig besteht er aus schwarzen und weißen Pixeln. Die verschlüsselten Daten können dann mit einem Smartphone oder Tablet schnell entschlüsselt und angezeigt werden.`,

    what_should_I_consider_when_printing_title: "Was sollte ich beim Drucken eines QR-Codes beachten?",
    what_should_I_consider_when_printing: `Wenn QR-Codes auf gedruckten Materialien verwendet werden, sollten hochauflösende Druckformate verwendet werden. QR Code Matic bietet QR-Codes in Formaten wie PNG, AI, EPS und SVG an. Diese Formate können ohne Qualitätsverlust vergrößert werden. Vektorformate sind besonders für großformatige Druckanwendungen geeignet. Bevor Sie den Code veröffentlichen, sollten Sie immer eine Testanwendung mit verschiedenen Smartphones und Apps durchführen.`,

    how_can_QR_codes_be_scanned_title: "Wie können QR-Codes gescannt werden?",
    how_can_QR_codes_be_scanned: `Wenn QR-Codes auf gedruckten Materialien verwendet werden, sollten hochauflösende Druckformate verwendet werden. QR Code Matic bietet QR-Codes in Formaten wie PNG, AI, EPS und SVG an. Diese Formate können ohne Qualitätsverlust vergrößert werden. Vektorformate sind besonders für großformatige Druckanwendungen geeignet. Bevor Sie den Code veröffentlichen, sollten Sie immer eine Testanwendung mit verschiedenen Smartphones und Apps durchführen.`,

    how_to_use_QR_codes_title: "Wie verwendet man QR-Codes?",
    how_to_use_QR_codes: `Mit der Verbreitung von Smartphones wird die Bedeutung von QR-Codes zunehmend größer. QR-Codes spielen eine Schlüsselrolle in Marketingkampagnen und werden es ermöglichen, ein größeres Publikum zu erreichen. QR-Codes werden heute vor allem für mobile Marketingzwecke eingesetzt. Sie können auf Flyern, Postern, Katalogen oder Visitenkarten sowie auf Websites, Videos, PDFs oder Kontaktdaten platziert werden. Die Anwendungsvielfalt ist nahezu unbegrenzt.`,

    can_I_customize_QR_codes_visually_title: "Kann ich QR-Codes visuell anpassen?",
    can_I_customize_QR_codes_visually: `Durch die hohe Fehlertoleranz ist es möglich, den QR-Code visuell anzupassen, ohne die Lesbarkeit zu beeinträchtigen. Zum Beispiel können Vordergrund- und Hintergrundfarben als Verlauf oder radial bestimmt werden, Sie können Ihr Logo in den QR-Code einfügen oder Muster für die Referenzpunkte und das Design der Matrix ändern. Achten Sie jedoch darauf, die Lesbarkeit zu testen, wenn Sie visuelle Anpassungen vornehmen.`,

    can_I_view_scan_statistics_of_QR_codes_title: "Kann ich die Scan-Statistiken von QR-Codes anzeigen?",
    can_I_view_scan_statistics_of_QR_codes: `Für Ihre dynamischen QR-Codes können Sie Echtzeit-Scan-Statistiken einsehen. Sie erhalten detaillierte Informationen über Länder, Städte, Browser, Geräte, Alter, Geschlecht, Gerätesauflösung und Downloads.`,

    what_file_formats_and_size_should_i_use_for_printing_title: "Welche Dateiformate und Größen sollte ich für den Druck verwenden?",
    what_file_formats_and_size_should_i_use_for_printing: `Für großformatige Druckanwendungen sind vektorbasierte Ausgaben empfehlenswert, da keine Qualitätsverluste beim Vergrößern auftreten. Achten Sie auch auf die Größe des QR-Codes, um sicherzustellen, dass er gut lesbar ist. Insbesondere bei statischen QR-Codes, die mehr Daten enthalten, müssen größere Größen verwendet werden, da mehr Daten den Code dichter machen. Der QR-Code sollte für eine einfache Benutzerinteraktion gut lesbar sein. Der Code sollte mindestens 2 x 2 cm groß sein. Die Größe des Codes sollte mindestens 1/10 der Leseentfernung betragen. Wenn der Code aus 10 Metern Entfernung gescannt werden soll, muss der QR-Code mindestens 1 Meter x 1 Meter groß sein. Der Code sollte nicht auf unebenen Oberflächen platziert werden, und Falten oder Krümmungen auf Druckmaterialien können die Lesbarkeit negativ beeinflussen.`,

    where_and_how_to_use_qr_code_title: "Wo und wie kann man QR-Codes verwenden?",
    where_and_how_to_use_qr_code: `Die Einsatzmöglichkeiten von QR-Codes sind grenzenlos. QR-Codes können von der Weitergabe persönlicher Informationen bis hin zur Lösung von Marketing- und Logistikproblemen eines Unternehmens eine Vielzahl von Möglichkeiten bieten.`,
  },
  WHY_QR_CODE: {
    unlimited_title: "Unbegrenzt",
    unlimited:
      "Mit QR-Codes von QRCodeMatic.com ermöglichen Sie Ihren Nutzern den Zugang zu unbegrenzten Videos, PDFs, Nachrichten, Informationen und allen Arten von Inhalten.",

    attractive_title: "Attraktiv",
    attractive:
      "Die Art des QR-Codes, den Sie verwenden, weckt auf natürliche Weise das Interesse der Menschen und weckt ihre Neugier. Mit der richtigen Gestaltung können Sie das Interesse steigern und die Rückmeldungen erhöhen. Da er ergebnisorientiert ist, wird auch die Kundenzufriedenheit deutlich steigen.",

    everywhere_is_a_showcase_for_you_title: "Jeder Ort ist Ihr Schaufenster",
    everywhere_is_a_showcase_for_you:
      "Jeder Ort, an dem Sie Ihre Produkte mit QR-Codes versehen haben, ist Ihr Schaufenster. Von Produkten, die von anderen Nutzern gesehen und gemocht werden, bis hin zu Broschüren, Visitenkarten und kostenlosen Snacks – überall ist Ihr lebendes Schaufenster.",

    add_on_your_product_title: "Fügen Sie Ihre Produktbroschüre auf Ihrem Produkt hinzu",
    add_on_your_product:
      "Anleitungen, Details, Anweisungen und Dokumente (Videos, PDFs, Dateien), die viel Platz auf den Seiten beanspruchen und leicht abgenutzt oder unleserlich werden können, können Sie auf Ihren Geräten, Verbrauchsgütern und allen anderen Ausrüstungsgegenständen anbringen. Außerdem können Sie den Nutzern auf einfache Weise spezielle Genehmigungen und Standards für Ihre Produkte zur Verfügung stellen, um Ihr Vertrauen zu erhöhen.",

    your_successful_sales_representative_title: "Ihr erfolgreicher Verkaufsrepräsentant",
    your_successful_sales_representative:
      "Ihre Produkte in Ihrem Schaufenster werden rund um die Uhr zur Empfehlung verfügbar und bieten den Nutzern eine unterhaltsame, ansprechende Einkaufserfahrung, indem sie den einfachen Zugang zu Ihren Produkten ermöglichen.",

    makes_you_easy_to_reach_title: "Macht es einfach, Sie zu erreichen",
    makes_you_easy_to_reach:
      "Dank der Integration von Google Maps können Sie mit nur einem Code Ihr gesamtes Unternehmensprofil anzeigen, einschließlich Ihres Standorts auf der Karte, Kontaktdaten, E-Mail, Nachrichten und sozialen Medien. So können Ihre Kunden Sie anrufen, eine E-Mail oder Nachricht senden, Ihre sozialen Medien erreichen, Sie zu ihren Kontakten und Adressen hinzufügen oder Ihre Standortinformationen erhalten.",

    it_is_social_title: "Es ist sozial",
    it_is_social:
      "Sie können anzeigen, wie Ihre Kunden alle Ihre Social-Media-Konten sehen, Ihre Marke verfolgen, Ihre Seite liken, Kommentare hinterlassen und Beschwerden oder Zufriedenheit melden.",

    lets_you_show_your_creativity_title: "Ermöglicht es Ihnen, Ihre Kreativität zu zeigen",
    lets_you_show_your_creativity:
      "Eine benutzerfreundliche Marketingplattform für Ihre besonderen Veranstaltungen, Gewinnspiele, Kampagnen und Rabatte. Sie können Ihren Kunden interessante Anwendungen präsentieren, um Ihre Geschenkgutscheine, Veranstaltungen und rabattierten Produkte digital zu erstellen, die Ihre Markenbekanntheit steigern. Die Grenzen dieser Anwendungen sind nur Ihre Kreativität und Fantasie.",

    low_cost_title: "Geringe Kosten",
    low_cost:
      "Angesichts der erzielten Gewinne ist es ein äußerst kostengünstiges Werbe- und Marketinginstrument. Weitere Details finden Sie auf unserer Website Qrcodematic.com.",

    durable_title: "Langlebig",
    durable:
      "Angesichts der erzielten Gewinne ist es ein äußerst kostengünstiges Werbe- und Marketinginstrument. Weitere Details finden Sie auf unserer Website Qrcodematic.com.",
  },
  BILLING: {
    when_can_i_use_my_package_title: "Wann kann ich mein Paket nutzen?",
    when_can_i_use_my_package:
      "Nachdem Sie Ihre Zahlung eingegeben und Ihre Bestellung aufgegeben haben, werden alle Funktionen Ihres Plans sofort freigeschaltet, und Sie können sofort mit der Erstellung neuer QR-Codes oder der Verwaltung Ihrer kostenlosen Testcodes beginnen.",

    can_i_upgrade_to_a_higher_plan_title: "Kann ich auf einen höheren Plan upgraden?",
    can_i_upgrade_to_a_higher_plan:
      "Ja. Wenn Sie das Limit für dynamische QR-Codes erreicht haben oder weitere Funktionen freischalten möchten, können Sie jederzeit auf einen höheren Plan upgraden. Wir werden Ihnen später eine anteilige Rückerstattung gewähren.",

    how_long_is_the_contract_period_title: "Wie lange ist die Vertragslaufzeit?",
    how_long_is_the_contract_period:
      "Wenn der Vertrag nicht gekündigt wird, verlängert er sich automatisch für die von Ihnen gewählte Vertragslaufzeit.",

    how_do_i_cancel_my_subscription_title: "Wie kündige ich mein Abonnement?",
    how_do_i_cancel_my_subscription:
      "Wenn Sie eine automatische Vertragsverlängerung verhindern möchten, reicht eine informelle E-Mail völlig aus. Ihre Kündigung muss spätestens 30 Tage vor dem Ende der ursprünglichen Vertragslaufzeit bei uns eingehen.",
  },
  ANALYTIC: {
    what_kind_of_analytics_reporting_do_you_offer_title: `Welche Art von Analyseberichten bieten Sie an?`,
    what_kind_of_analytics_reporting_do_you_offer: `Für umgeleitete URLs, die unseren URL-Shortener-Dienst nutzen, werden Analyse-Daten in Ihrem Kontodashboard in durchsuchbaren Datumsformaten angezeigt, die jedes Scan-Ereignis mit Datum, Uhrzeit, Gerätetyp und Standort angeben. Die Daten werden sowohl in Tabellen- als auch in Grafikformaten angezeigt und können auch als Excel-Datei oder herunterladbarer PDF-Bericht exportiert werden. Die Berichterstattung ist für jeden einzigartigen dynamischen QR-Code verfügbar. Klicken Sie hier, um einen Screenshot zu sehen oder ein Beispiel-PDF-Bericht herunterzuladen.`,

    can_analytics_be_used_with_all_your_QR_codes_title: `Können Analysen mit allen Ihren QR-Codes verwendet werden?`,
    can_analytics_be_used_with_all_your_QR_codes: `Nein. Analysedaten können nur gesammelt werden, wenn Sie einen dynamischen QR-Code erstellen, der es dem Nutzer ermöglicht, über die kodierte Kurz-URL auf unsere Website zurückzukehren und uns gleichzeitig die erforderlichen Daten zur Verfügung zu stellen. Viele andere QR-Code-Ersteller-Websites zwingen Sie, einen dynamischen QR-Code zu erstellen, ob Sie wollen oder nicht. Bei uns haben wir uns jedoch entschieden, dies optional zu machen, um sicherzustellen, dass beim Scannen des QR-Codes nicht die URL unserer Website angezeigt wird, sondern die tatsächliche Ziel-URL. Der Nachteil dabei ist, dass bei der Erstellung eines statischen QR-Codes kein Tracking-Mechanismus im QR-Code selbst enthalten ist. Wenn Sie einen statischen QR-Code erstellen, wird der Besucher den QR-Code scannen und direkt zur Ziel-URL oder dem kodierten Inhalt weitergeleitet, ohne dass er durch unseren Server geht. Das bedeutet, dass wir nicht in der Lage sind, Tracking-Daten zu sammeln. In diesem Fall müssen Sie Google Analytics oder ein ähnliches Tool auf der Ziel-URL verwenden. Unser Blog erklärt, wie Sie Google Analytics mit QR-Codes verwenden können. Besonders wenn Sie sich für die Erstellung eines statischen QR-Codes entscheiden, ist die Analyseberichterstattung nicht verfügbar und kann später nicht hinzugefügt werden. Die Wahl zwischen statisch und dynamisch ist einmalig und kann danach nicht geändert werden.`,

    how_can_I_access_my_analytics_reports_title: `Wie kann ich auf meine Analyseberichte zugreifen?`,
    how_can_I_access_my_analytics_reports: `Wenn Sie sich in Ihr Abonnement-Konto einloggen, können Sie Ihr Dashboard über den Link in der rechten oberen Ecke der Seite erreichen. In den historischen Berichten öffnet der "Verwalten"-Tab neben jedem QR-Code-Eintrag erweiterte Informationen zu diesem QR-Code. Jeder dynamische QR-Code, den Sie erstellen, zeigt rechts eine "Analytics"-Verknüpfung in der Detailübersicht dieses QR-Codes an. Wenn die "Analytics"-Option nicht angezeigt wird, handelt es sich um einen statischen QR-Code, für den keine Analyseberichte verfügbar sind.`,

    are_phone_numbers_and_email_addresses_included_in_analytics_reports_title: `Sind Telefonnummern und E-Mail-Adressen in den Analyseberichten enthalten?`,
    are_phone_numbers_and_email_addresses_included_in_analytics_reports: `Nein. Aufgrund der Datenschutz- und Sicherheitsprotokolle, die auf den Telefonen selbst eingebaut sind, haben wir keinen Zugriff auf identifizierbare Nutzerinformationen (wie Telefonnummern und E-Mail-Adressen), die auf dem Telefon gespeichert sind und zum Scannen des QR-Codes verwendet werden.`,
  },
login_note: `<div style="font-weight: 900">WICHTIG!</div> <span class="demo-time"> Nachdem die Testphase abgelaufen ist,</span> können Sie weiterhin
  <span class="font-weight-bolder"> KOSTENLOS statische QR-Codes</span> erstellen und <span class="font-weight-bolder"> IHR ABONNEMENT</span> weiterhin nutzen.`,

MENU: {
    NEW: "Neu",
    ACTIONS: "Aktionen",
    CREATE_POST: "Neuen Beitrag erstellen",
    PAGES: "Seiten",
    FEATURES: "Funktionen",
    APPS: "Apps",
    DASHBOARD: "Dashboard",
    MARKET: "Markt",
    MYQRCODES: "Meine QR-Codes",
    ALLQRCODES: "Alle QR-Codes",
    ACTIVEQRCODES: "Aktive QR-Codes",
    PASSIVEQRCODES: "Passive QR-Codes",
    DELETEDQRCODES: "Gelöschte QR-Codes",
    MASTERQRCODES: "Massenweise hinzugefügte QR-Codes",
    SUBSCRIPTIONS: "Preise und Pakete",
    MYFOLDERS: "Meine Ordner",
    QRFOLDERS: "QR-Code-Ordner",
    MYMEDIA: "Meine Medien",
    ADDNEWFOLDER: "Neuen Ordner hinzufügen",
    CREATENEWQRCODE: "Neuen QR-Code erstellen",
    MYACCOUNT: "Mein Konto",
    PAIDPLAN: "Zahlungsplan",
    ORDERHISTORY: "Bestellverlauf",
    SUBMITTICKET: "Ticket einreichen",
    HELP: "Hilfe",
    PACKAGE_HISTORY: "Paketverlauf",
    DAY_REMANING: "Verbleibende Tage",
    SCAN: "Scannen",
    STATIC: "Statisch",
    DYNAMIC: "Dynamisch",
    PACKAGE_TITLE: "PAKETDETAILS",
    CORPARATE: "Unternehmen",
    TITLE: "Menü",
    BULKPRICE: "Ermöglicht das schnelle Aktualisieren der Menüpreise",
  },

QRLIST: {
    STATIC: "Statisch",
    PREVIEW: "Sie können eine Vorschau Ihrer erstellten QR-Codes anzeigen.",
    DYNAMIC: "Dynamisch",
    MULTIPLY_QRCODE: "QR-Code vervielfältigen",
    BULK_CREATE:
      "Unter diesem QR-Code können Sie neue QR-Codes hinzufügen und die Inhalte sowie Designs der untergeordneten QR-Codes in Massen aktualisieren.",
    BULK_UPLOAD: "Massen-Upload",
    BULK_UPDATE: "Massen-Aktualisierung",
    BULK_SETTINGS: "Massen-Upload/Aktualisierung Einstellungen",
    CLICK_TO_DOWNLOAD: "Zum Herunterladen klicken",
    CLICK_TO_UPLOAD: "Klicken Sie hier, um Ihre Excel-Datei hochzuladen",
    CLICK_TO_UPDATE: "Klicken Sie hier, um zu bearbeiten",
    SELECT_FILE: "Datei auswählen",
    DOWNLOAD_SAMPLE_FILE: "Beispieldatei herunterladen",
    DOWNLOAD_SUCCESSFUL: "Herunterladen erfolgreich!",
    CLICK_HERE_TO_CUSTOMIZE: "Klicken Sie hier, um die Farben und Designs Ihres QR-Codes anzupassen!",
    CLICK_HERE_TO_FOLDER_CUSTOMIZE:
      "Erstellen Sie einen Ordner im linken Menü unter 'Neuen Ordner hinzufügen' und filtern Sie die gewünschten QR-Code-Typen für Ihre Kampagnen.",
    CLICK_HERE_TO_EDIT: "Klicken Sie auf den Titel, um die Inhaltsinformationen Ihrer Karte zu bearbeiten!",
    NON_TRACKABLE: "Statisch / Nicht verfolgbar",
    MY_QR_CODE: "Sie sind dabei, das My QR Card mit der ID zu löschen",
    DOWNLOAD_IN_PDF_FORMAT: "Im PDF-Format herunterladen",
    DOWNLOAD_IN_PNG_FORMAT: "Im PNG-Format herunterladen",
    DOWNLOAD_IN_SVG_FORMAT: "Im SVG-Format herunterladen",
    DOWNLOAD_IN_EPS_FORMAT: "Im EPS-Format herunterladen",
    DESC: `Bitte testen Sie Ihren QR-Code, bevor Sie ihn herunterladen, auf verschiedenen Geräten. Für den Druck können Sie .svg, .eps, .pdf verwenden, und für Ihre Website .png. 
    Auf diese Weise können Sie alle Ihre Dokumente, einschließlich sehr großer Poster, in gedruckter Form oder für Designzwecke verwenden. Sobald Sie Ihre Überprüfungen abgeschlossen haben, sind Ihre QR-Codes druckbereit.`,
    NOTE: "Hinweis: Radial- und Gradient-QR-Codes können mit der .svg-Dateiendung heruntergeladen und geöffnet werden.",
    BULK_UPLOAD_DESC:
      "Um mehrere QR-Codes gleichzeitig zu erstellen, müssen Sie die Beispieldatei herunterladen und die Felder mit den richtigen Informationen für Ihr Personal ausfüllen. Jede Datenzeile erstellt einen neuen QR-Code. Klicken Sie auf das Excel-Symbol unten, um die Beispieldatei herunterzuladen, und verwenden Sie die Hochladen-Schaltfläche, um Ihre Datei zu senden.",
    BULK_CREATE_DESC: "Laden Sie alle QR-Codes gleichzeitig herunter und bearbeiten Sie deren Inhalte.",
    BULK_UPDATE_DESC: "Laden Sie alle QR-Codes gleichzeitig herunter und bearbeiten Sie deren Inhalte.",
    UPLOAD_YOUR_FILE: "Datei hochladen",
  },
  CREATEQR: {
    MENU: {
      DYNAMIC: {
        TITLE: "Dinamik QR Code",
        ITEMS: {
          WEBSITE: "Website",
          RESTAURANT: "Restoran",
          FEEDBACK: "Geri Bildirim",
        },
      },
      STATIC: {
        TITLE: "Statik QR Code",
        ITEMS: {},
      },
    },
    WIZARD: {
  BASIC_INFO: {
    TITLE: "Fügen Sie Ihre Inhaltsinformationen hinzu",
    CATEGORIES: "Kategorien",
    CATEGORY_IMAGE: "Kategoriebild",
    SELECT_IMAGE: "Bild auswählen",
    LOADER_TEXT: "",
    THIS_URL_IS_INVALID: "Diese URL ist ungültig",
    FIELDS: {
      key: "Feld",
      value: "Wert",
      start: "Start",
      end: "Ende",
      video: "Video",
      title: "Titel",
      image: "Bild",
      text: "Text",
      color: "Farbe",
      description: "Beschreibung",
      name: "Name",
      number: "Nummer",
      phone: "Telefon",
      buttonText: "Schaltflächentext",
      url: "URL",
      BASIC_INFORMATION: "Allgemeine Informationen",
      SETTINGS: "Einstellungen",
      LANGUAGE: "Sprache auswählen",
      ACTIVE_LANGUAGE: "Aktive Sprache",
      ADD_SOME_INFO: "Bitte geben Sie die erforderlichen Informationen ein",
      FULL_NAME: "Vollständiger Name",
      COMPANY_NAME: "Firmenname",
      JOB_TITLE: "Berufsbezeichnung",
      DEPARTMENT: "Abteilung",
      BLOOD_GROUP: "Blutgruppe",
      FOLDER_NO: "Akte-Nummer",
      DATE_OF_START: "Eintrittsdatum",
      COMPANY: "Firma",
      MAIL: "E-Mail",
      WEBSITE: "Website",
      PHONE: "Telefon",
      ADDRESS: "Adresse",
      ENTER_LOCATION: "Standort auswählen",
      PLEASE_CHOOSE_LOCATION: "Wählen Sie zuerst den Standort aus",
      MANAGER_FULL_NAME: "Vollständiger Name des Managers",
      MANAGER_PHONE: "Telefonnummer des Managers",
      MANAGER_JOBTITLE: "Berufsbezeichnung des Managers",
      MANAGER_MAIL: "E-Mail des Managers",
      ART_NAME: "Kunstwerkname",
      ART_ACCESSION_NUMBER: "Inventarnummer",
      ART_ERA: "Zeitraum",
      ART_MEASUREMENT: "Maße",
      ART_DISCOVERY_LOCATION: "Fundort",
      ART_ACCESSION_DATE: "Zugang zum Inventar Datum",
      ART_DESCRIPTION: "Kunstwerkbeschreibung",
      RESTAURANT_ABOUT_US: "Über uns im Restaurant",
      RESTAURANT_ADDRESS: "Restaurantadresse",
      RESTAURANT_PHONE: "Restauranttelefon",
      RESTAURANT_EMAIL: "Restaurant E-Mail",
      BLOODGROUP: "Blutgruppe",
      MEDICATION: "Medikamentenbehandlung",
      PLATENUMBER: "Kennzeichen",
      ALLERGICTO: "Allergien",
      DESCRIPTION: "Beschreibung",
      RESPONSIBLESTAFFPHONE: "Verantwortliche Person",
      CITY: "Stadt",
      FAX: "Fax",
      ZIP: "Postleitzahl",
      ADD_A_NEW_DOCUMENT: "Neues Dokument hinzufügen",
      QR_CODE_TITLE: "QR-Code-Titel",
      general_info: "Allgemeine Informationen",
      SHOW_CONTACT_FROM: "Kontaktformular anzeigen",
      SHOW_GOOGLE_MAPS: "Google Maps anzeigen",
      SHOW_ADD_TO_CONTACTS: "Option 'Zu Kontakten hinzufügen' anzeigen",
      SHOW_ADDRESS_FIELD: "Adressfeld anzeigen",
      ADDITIONAL_INFO: "Zusätzliche Informationen",
      SHOW_PHONE_ICON: "Telefon-Icon anzeigen",
      SHOW_MAIL_ICON: "E-Mail-Icon anzeigen",
      CONTACT_INFO: "Kontaktinformationen",
      FIELDS: "Felder",
      YOU_HAVE_TO_FILL_THE_FIELDS_BEFORE_APPLY: "Sie müssen die Felder ausfüllen, bevor Sie sich bewerben können.",
      SELECT_PDF: "Wählen Sie PDF",
      FDF_MENU_IF_YOU:
        "Wenn Sie für jedes Restaurant einzigartige Menüs verwenden möchten, können Sie hier mehr hinzufügen. Jedes Restaurant wird seinen eigenen QR-Code haben.",
      DESCRIPTION_FIELD: "Sie können hier eine Beschreibung für das Feld eingeben",
      CURRENT_RESTAURANTS: "Aktuelle Restaurants",
      NO_TITLE: "Kein Titel",
      NEW_RESTAURANT: "Neues Restaurant",
      BOOK_TITLE: "PDF-Titel",
      PERSONNEL_INFO: "Mitarbeiterinformationen",
      COMPANY_INFO: "Firmeninformationen",
      ENABLE_DOCUMENTS_PAGE: "Dokumentenseite aktivieren",
      ENABLE_REMINDER: "E-Mail-Erinnerung ein-/ausschalten",
      ENABLE_COUNTER: "Zähler ein-/ausschalten",
      CUSTOM_FIELDS: "Benutzerdefinierte Felder",
      CALL_LIST: "Anrufliste",
      PERSON: "Person",
      EMERGENCY_CALLS: "Notrufe",
      DOCUMENTS_LIST: "Dokumentenliste",
      GROUPS: "Gruppen",
      TITLE: "Titel",
      SCHEDULE_INFO: "Zeitplaninformationen",
      DAY: "Tag",
      SHOW_SCHEDULE_CALENDER: "Zeitplantabelle anzeigen",
      MANAGER_INFO: "Managerinformationen",
      MAPS_STYLE: "Kartendesign",
      TEXT_ADDRESS: "Text",
      TITLE_ADDRESS: "Titel",
      SONG: "Lied",
      ADD_MORE: "Mehr hinzufügen",
      ARTIST: "Künstler",
      NO_RESTRICTION: "Keine Einschränkung",
      ARTIFACT_INFORMATION: "Artefaktinformationen",
      ARTIFACT_INFO: "Strukturinformationen",
      STORE_INFORMATION: "Ladeninformationen",
      PLATFORM: "Plattform",
      OTHER_FIELDS: "Andere Felder",
      WIFINAME: "WLAN-Name",
      RANDOM_LINK: "Zufälliger Link",
      SHOW_RANDOM_LINK: "Zeigt ein zufälliges Element aus der Liste an.",
      SHOW_ALL_RANDOM_LINK: "Zeigt alle Elemente in der Liste an.",
      MULTIPLE_LINK: "Mehrere Links",
      LINKS: "Links",
      PRODUCT_NAME: "Produktname",
      GO_TO_WEBSITE: "Zur Website gehen",
      SELECT_ICON: "Icon auswählen",
      brochure_image: "Bannerbild",
      SELECT: "Auswählen",
      SELECT_IMAGE: "Bild auswählen",
      SELECT_RELATED_CATEGORY: "Verwandte Kategorie auswählen",
      ABOUT_US_PAGE_INFO: "Über uns-Seiteninformationen",
      CONTACT_PAGE_INFO: "Kontaktseiteninformationen",
      RESERVATION_PAGE_INFO: "Reservierungsseiteninformationen",
      ABOUT_US_PAGE: "Über uns-Seite",
      CONTACT_PAGE: "Kontaktseite",
      RESERVATION_PAGE: "Reservierungsseite",
      BROCHURES: "Broschüren",
      BANNER: "Banner",
      type: "Typ",
      REDIRECT: "Weiterleitung",
      SONGURL: "Fügen Sie den Liedlink ein...",
      isActive: "Aktiv/Inaktiv",
      proximity: "Proximität",
      sort_order: "Sortierreihenfolge",
      SHOW_MIDDLE_SOCIAL_MEDIA_BUTTONS: "Soziale Medien-Buttons in der Mitte anzeigen",
      fileUrl: "Datei-URL",
      fileFormat: "Dateiformat",
    },
  },
  VALIDATION: {
    VAILD_PHONE_NUMBER: "Bitte geben Sie eine gültige Telefonnummer ein",
  },
  CUSTOMIZE: {
    TITLE: "Design & Anpassung",
    FIELDS: {
      CUSTOMIZE_TEXT: "Passen Sie Ihren Text an",
      CUSTOMIZE_YOUR_PAGE: "Passen Sie Ihre Seite an, indem Sie eine Farbe auswählen",
      SINGLE_COLOR: "Einzelne Farbe",
      FIRST_COLOR: "Erste Farbe",
      SECOND_COLOR: "Zweite Farbe",
      BG_COLOR: "Hintergrundfarbe",
      GRADIENT: "Verlauf",
      COLOR: "Farbe",
      PRIMARY: "Primärfarbe",
      TEXT_COLOR: "Textfarbe",
      BUTTONS: "Schaltflächen",
      CHOOSE_BACKGROUND: "Wählen Sie Hintergrund oder Farbe",
      IMAGE: "Bild",
      CHOOSE_FILE_DROP: "Wählen Sie eine Datei aus oder ziehen Sie sie hierher",
      BODY_OPTION: "Körperoptionen",
      LINEAR: "Linear",
      RADIAL: "Radial",
      TRANSPARENT_BACKGROUND: "Transparenter Hintergrund",
      CUSTOMIZE_YOUR_PAGE_LAYOUT:
        "Sie können das Layout Ihrer Seite anpassen, indem Sie die unten stehenden Optionen aktivieren/deaktivieren.",
      CONTACT_EMAIL_LABEL: "E-Mail-Adresse, an die das Kontaktformular gesendet wird",
    },
  },
  SOCIAL_MEDIA_CHANNELS: {
    TITLE: "Soziale Medien Links",
    FIELDS: {
      LINK_YOUR_SOCIAL: "Fügen Sie Ihre sozialen Medien-Links hinzu",
      YOU_CAN_REMOVE: "Klicken Sie auf das Kreuz, um einen beliebigen Link zu entfernen",
      ADD_MORE: "Mehr hinzufügen",
    },
  },
  MEDIA: {
    TITLE: "Fügen Sie Bilder & Videos hinzu",
    FIELDS: {
      UPLOAD_PROFILE_PHOTO: "Laden Sie ein Profilfoto hoch",
      PHOTO: "Foto",
      STORY: "Geschichte",
      NOTE: "Für die besten Ergebnisse wählen Sie Fotos im Verhältnis 1:1 (z.B. 300px/300px)",
      CHOOSE_MEDIA: "Wählen Sie Mediainhalte aus Ihrem lokalen Speicher aus",
      VIDEO: "Video",
      CHOOSE_STORY_VIDEO: "Laden Sie ein Video für Ihre Story hoch",
      YOUTUBE_VIDEO: "Fügen Sie den YouTube-Video-Link ein",
      PHOTOS: "Fotos",
      CHOOSE_PHOTO: "Wählen Sie Fotos für Ihre Stories aus",
    },
  },
  CATEGORY: {
    TITLE: "Kategorien & Produkte",
  },
  WELCOME_SCREEN: {
    TITLE: "Willkommensbildschirm anpassen",
        FIELDS: {
              LOADING_SCREEN_LOGO: "Willkommensbildschirm-Logo",
              SELECT_FROM_MEDIA: "Wählen Sie aus meinen Medien",
              SELECT_FROM_ANIMATIONS: "Fügen Sie Ihr eigenes Logo hinzu",
              LOADING_SCREEN_BACKGROUND: "Hintergrund des Willkommensbildschirms",
              SINGLE_COLOR: "Einzelne Farbe",
              GRADIENT: "Verlauf",
              YOUR_INFORMATION: "Ihre Informationen",
              FIRST_FIELD: "Ladehinweis",
              SECOND_FIELD: "Zweites Feld",
            },
          },
          ADVANCED_SETTINGS: {
            TITLE: "Erweiterte Einstellungen",
            FIELDS: {
              PASSWORD: "Passwort",
              AGE_BELOW: "Altersbeschränkung",
              PASS_CODE_HERE: "Fügen Sie den Code hier ein...",
              TIME_ZONE: "Zeitzone",
              PICK_START_DATE: "Startdatum auswählen",
              CANCEL: "Abbrechen",
              OK: "OK",
              STARTING_DATE: "Startzeit",
              ENDING_DATE: "Endzeit",
              PICK_END_DATE: "Enddatum auswählen",
            },
          },
          CREATEQR: "QR-Code erstellen",
          UPDATE_CHANGES: "Änderungen aktualisieren",
        },
    FOLLOWALBE: "Verfolgbare (Kostenpflichtig)",
    NOT_TRACKED: "Nicht verfolgbar (Lebenslang kostenlos)", 
  },
RESTAURANT: {
  title: "Titel: ",
  description: "Beschreibung: ",
  cover_image: "Bild: ",
  category_icon: "Kategorien-Icon: ",
  categories: "Kategorien",
  category: "Kategorie",
  product: "Produkt",
  products: "Produkte: ",
  product_name: "Produktname",
  preparation: "Zubereitungszeit: ",
  calorie: "Kalorien",
  ingredients: "Zutaten: ",
  price: "Preis: ",
  price_label: "Preistitel",
  likes: "Gefällt mir: ",
  current_categories: "Aktive Kategorien",
  add_new_field: "Neues Feld Hinzufügen",
  add_new_category: "Neue Kategorie Hinzufügen",
  add_new_product: "Neues Produkt Hinzufügen",
  add_new_price: "Hinzufügen",
  please_add_product: "Bitte fügen Sie ein Produkt hinzu",
  brochure_slider: "Banner",
  delete_product: "PRODUKT LÖSCHEN",
  delete_category: "KATEGORIE LÖSCHEN",
  new_category_title: "Neue Kategorie",
  new_category_description: "Beschreibung der neuen Kategorie",
  new_product_title: "Neues Produkt",
  new_product_description: "Beschreibung des neuen Produkts",
  status_desc: "Passive Kategorien werden von der Seite entfernt.",
  status: "Status",
  detailed_title: ({ named }) => `${named("value")} Detail`,
},
ERRORS: {
    err_1: "Fehler; Entschuldigung, aber ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
    err_2: "Fehler; Entschuldigung, ein unerwarteter Fehler trat beim Hochladen des Avatars auf. Bitte versuchen Sie es erneut.",
    err_3: "Fehler; Entschuldigung, die Verbindungsadresse ist ungültig. Bitte verwenden Sie eine gültige Verbindungsadresse.",
    err_4: "Fehler; Entschuldigung, diese E-Mail wurde bereits bestätigt. Versuchen Sie es mit einer anderen E-Mail.",
    err_6: "Fehler; Entschuldigung, ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
    err_7: "Fehler; Entschuldigung, es wurden zu viele Anfragen gestellt. Bitte versuchen Sie es in einer Minute erneut.",
    err_8: "Fehler; Ihr Konto ist nicht verifiziert! Bitte klicken Sie auf den 'Erneut senden' Button unter Ihrem Passwort, um die Bestätigungs-E-Mail erneut zu erhalten.",
    err_9: "Fehler; Entschuldigung, das Passwort ist falsch. Bitte geben Sie das richtige Passwort ein.",
    err_10: "Fehler; Entschuldigung, ein Benutzer mit dieser Adresse wurde nicht gefunden.",
    err_11: "Fehler; Entschuldigung, Sie haben die Nutzungsbedingungen nicht akzeptiert. Bitte stimmen Sie den Bedingungen zu.",
    err_12: "Fehler; Entschuldigung, der Benutzer wurde gelöscht. Bitte wenden Sie sich an unser Support-Team.",
    err_13: "Fehler; Entschuldigung, das Benutzerkonto wurde gesperrt. Bitte wenden Sie sich an unser Support-Team.",
    err_14: "Fehler; Entschuldigung, das Konto ist inaktiv. Bitte wenden Sie sich an unser Support-Team.",
    err_15: "Fehler; Ihr aktuelles Paket läuft in mehr als einem Jahr ab. Bitte wenden Sie sich an unser Support-Team.",
    err_16: "Warnung!!!; Eine ausstehende Überweisung ist vorhanden. Um eine neue Bestellung aufzugeben, müssen Sie zunächst Ihre aktuelle Bestellung stornieren. Möchten Sie fortfahren?",
    err_17: ({ named }) => `Fehler; Entschuldigung, der ${named("value")} MB große Speicher ist voll. Sie müssen Platz schaffen, um eine neue Datei hochzuladen. Sie können sich auch an unser Support-Team wenden.`,
    err_18: "Fehler; Dieser Code wurde bereits verwendet. Bitte versuchen Sie einen anderen Code.",
    err_19: "Fehler; Ungültiger Code. Bitte geben Sie einen gültigen Code ein.",
    err_20: "Fehler; Der Code ist abgelaufen. Bitte versuchen Sie es mit einem neuen Code.",
    err_21: "Fehler; Der Wechsel zu einem niedrigeren Paket ist nicht möglich.",
    err_22: "Fehler; Ungültiger Code. Bitte geben Sie einen gültigen Code ein.",
    err_23: "Fehler; Ungültiger Schlüssel. Bitte geben Sie einen gültigen Schlüssel ein.",
    err_24: "Fehler; Ein Benutzer mit dieser E-Mail-Adresse wurde nicht gefunden. Bitte verwenden Sie eine gültige E-Mail-Adresse.",
    err_25: "Fehler; Der Dateiname darf nicht leer sein.",
    err_26: "Fehler; Dieser Dateiname wurde bereits verwendet. Bitte wählen Sie einen anderen Dateinamen.",
    err_100: "Fehler; Das E-Mail-Feld ist erforderlich.",
    err_101: "Fehler; Das E-Mail-Feld muss vom Typ Text sein.",
    err_102: "Fehler; Bitte geben Sie eine gültige E-Mail-Adresse ein.",
    err_103: "Fehler; Das E-Mail-Feld darf maximal 255 Zeichen lang sein.",
    err_104: "Fehler; Diese E-Mail-Adresse ist bereits registriert.",
    err_105: "Fehler; Die E-Mail-Adresse wird nicht akzeptiert. Bitte geben Sie eine akzeptierte E-Mail-Endung ein.",
    err_106: "Fehler; Das Namensfeld ist erforderlich.",
    err_107: "Fehler; Das Namensfeld muss vom Typ Text sein.",
    err_108: "Fehler; Das Namensfeld darf maximal 255 Zeichen lang sein.",
    err_109: "Fehler; Das Passwortfeld ist erforderlich.",
    err_110: "Fehler; Das Passwort muss mindestens 6 Zeichen lang sein.",
    err_111: "Fehler; Das Passwort muss mindestens einen Buchstaben enthalten.",
    err_112: "Fehler; Das Passwort muss mindestens eine Zahl enthalten.",
    err_113: "Fehler; Das Länderfeld ist erforderlich.",
    err_114: "Fehler; Ungültiges Land ausgewählt.",
    err_115: "Fehler; Das Organisationsfeld ist erforderlich.",
    err_116: "Fehler; Sie müssen den Nutzungsbedingungen zustimmen.",
    err_404: "Fehler; Der gesuchte Inhalt wurde nicht gefunden. Bitte geben Sie den richtigen Seiten- oder Inhaltsnamen ein.",
    err_500: "Fehler; Entschuldigung, ein unbekannter Fehler ist aufgetreten. Bitte laden Sie die Seite neu und versuchen Sie es erneut. Wenn der Fehler weiterhin besteht, kontaktieren Sie uns bitte.",
    err_1001: "Fehler; Entschuldigung, Sie haben keine Berechtigung für diese Aktion. Bitte wenden Sie sich an eine berechtigte Person.",
    err_1002: "Fehler; Entschuldigung, Ihr Paket ist abgelaufen. Bitte versuchen Sie, ein neues Paket zu kaufen.",
    err_1003: "Fehler; Entschuldigung, Sie haben das maximale Limit für Ihr Paket erreicht. Sie können keine weiteren Inhalte hinzufügen.",
    err_1004: "Fehler; Sie haben nicht den richtigen QR-Code-Typ. Sie können zu einem Paket wechseln, das diesen QR-Code-Typ enthält.",
    err_1005: "Fehler; Sie haben die maximale Anzahl von 100 überschritten. Weitere Hinzufügungen sind nicht möglich.",
    err_1006: "Fehler; Ihre Verbindungsbestätigungen sind fehlgeschlagen. Bitte verwenden Sie die richtigen Verbindungsinformationen.",
    err_1007: ({ named }) => `Errore: Hai superato il limite valido di (${named("value")}), quindi non puoi generare il codice QR statico.`,
    err_1008: ({ named }) => `Fehler; Da Ihr Paket abgelaufen ist, können Sie nur ${named("value")} Ordner erstellen.`,
    err_2001: "Fehler; Ungültiges Zeichen. Bitte verwenden Sie ein gültiges Zeichen.",
    err_2002: "Fehler; Diese URL ist bereits vorhanden. Bitte verwenden Sie eine andere URL.",
    err_2003: "Fehler; Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
    err_2004: "Fehler; Die Werte dürfen nicht leer sein. Bitte geben Sie einen gültigen Standardwert ein.",
    err_2005: "Fehler; Der Standardwert muss mindestens 3 Zeichen lang sein. Bitte geben Sie einen längeren Wert ein.",
    err_2006: "Fehler; Der Standardwert darf nicht mehr als 30 Zeichen lang sein. Bitte geben Sie einen kürzeren Wert ein.",
    err_2007: "Fehler; Sie müssen ein Bild auswählen. Bitte wählen Sie ein Bild aus.",
    err_2008: "Fehler; Sonderzeichen sind nicht erlaubt. Sie können nur Zahlen und Buchstaben verwenden.",
    err_2009: "Fehler; Sie müssen eine Datei auswählen. Bitte wählen Sie eine Datei aus.",
    err_2010: "Fehler; Nur Dateien werden akzeptiert. Bitte laden Sie gültige Dateien hoch.",
    err_2011: "Fehler; Nur Dateien bis maximal 25 MB dürfen hochgeladen werden. Bitte laden Sie eine kleinere Datei hoch.",
    err_2012: "Fehler; Sie haben diese Datei bereits hochgeladen. Bitte wählen Sie eine Datei mit einem anderen Namen.",
    err_2013: "Fehler; Sie haben noch eine laufende Transaktion. Sobald sie abgeschlossen ist, erhalten Sie eine E-Mail. Versuchen Sie es später erneut.",
    err_2014: "Fehler; Im Testpaket können Sie maximal 10 QR-Codes auf einmal hochladen. Für mehr müssen Sie Ihr Paket aktualisieren.",
    err_2015: "Fehler; Ihre Excel-Datei darf maximal 400 Zeilen umfassen. Bitte aktualisieren Sie die Datei und versuchen Sie es erneut.",
    err_2016: "Fehler; Sie haben keine Sammel-Download-Dateien. Bitte versuchen Sie es nach dem Hochladen erneut.",
    err_2017: "Fehler; Sie haben das Limit für das Sammel-Upload überschritten. Bitte kontaktieren Sie uns.",
    err_2017: "Fehler; Um eine neue Excel-Datei hochladen zu können, löschen Sie bitte mindestens eine alte Datei.",
    err_2019: "Fehler; Unzulässige oder weiterleitende Links wurden in Ihren Daten gefunden. Bitte ändern Sie diese und versuchen Sie es erneut.",
    err_2020: "Fehler; Es dürfen nur Excel-Dateien mit der Endung .xlsx hochgeladen werden. Überprüfen Sie den Dateityp und versuchen Sie es erneut.",
    err_2021: "Fehler; Excel-Dateien dürfen maximal 2 MB groß sein. Bitte laden Sie eine kleinere Datei hoch.",
    err_2022: "Fehler; Für diese Aktion müssen Sie mindestens 2 QR-Codes haben.",
    err_2023: "Fehler; Bitte wählen Sie mindestens 1 Unter-QR-Code aus.",
    err_2024: "Fehler; Um diese Aktion auszuführen, benötigen Sie mindestens 1 Haupt-QR-Code. Bitte wählen Sie mindestens 1 Unter-QR-Code aus.",
    err_2025: ({ named }) => `Fehler; Es darf nur eine Datei mit maximal ${named("value")} MB hochgeladen werden. Bitte laden Sie eine kleinere Datei hoch.`,
    err_2026: "Fehler; Es darf nur ein Bild mit einer maximalen Breite von 1000 px hochgeladen werden. Bitte passen Sie die Größe des Bildes an, sodass es maximal 1000 px in der Breite und/oder Höhe hat.",
    err_2027: "Fehler; Das Format der Domain ist ungültig.",
    err_2028: "Fehler; Diese Domain wurde bereits hinzugefügt.",
    err_2029: "Fehler; Der CNAME-Eintrag konnte nicht verifiziert werden.",
  },
  PAYMENT: {
    unknown_reason: "Unbekannter Grund",
    payment: "Zahlung",
    successful: "ERFOLGREICH",
    failed: "FEHLGESCHLAGEN",
    awaiting: "WARTET",
    reason: "Grund",
    payment_successful_desc: `Sehr geehrter Kunde,
    Ihre Bestellung wurde erfolgreich entgegengenommen und Ihr Paket wurde Ihrem Konto zugewiesen.
    Bei Fragen oder Bedenken können Sie sich jederzeit gerne an uns wenden. Wir freuen uns, Ihnen weiterhelfen zu können.
    Vielen Dank für Ihr Interesse und Vertrauen!`,
    payment_failed_desc: `Sehr geehrter Kunde,
    Es tut uns leid, aber wir haben einen Fehler bei Ihrer Zahlung festgestellt. Sie können sich gerne an uns wenden, um Unterstützung zu erhalten.
    Wir freuen uns, Ihnen weiterhelfen zu können.`,
    payment_awaiting_desc: `Sehr geehrter Kunde,
    Vielen Dank für Ihre Bestellung! Der letzte Schritt zur Bearbeitung Ihrer Bestellung ist der Abschluss Ihrer Zahlung.
    Bei einer Überprüfung in unserem System haben wir festgestellt, dass Ihre Zahlung noch nicht abgeschlossen wurde.
    Bitte schließen Sie Ihre Zahlung ab, indem Sie eine Überweisung auf unser Bankkonto vornehmen.`,
    title_success: () => `<strong>Vielen Dank</strong>, Ihre Bestellung wurde entgegengenommen!`,
    title_fail: () => `<strong>Es tut uns leid</strong>, Zahlung fehlgeschlagen!`,
    title_awaiting: () => `<strong>Danke</strong>, Ihre Bestellung wurde entgegengenommen!`,
    err_105: "Zahlung vom Benutzer abgebrochen",
    err_000: "Zahlung fehlgeschlagen",
},
SNACKBAR: {
    qrcode_updated: "QR-Code aktualisiert!",
    qrcode_created: "QR-Code erstellt!",
    verify_email: "E-Mail muss bestätigt werden",
    login_successful: "Erfolgreich eingeloggt",
    logged_out: "Abgemeldet!",
    registeration_successful: "Erfolgreiche Registrierung",
},
DIALOG: {
    STEP_BY_STEP_TOUR_TITLE: "Willkommen zur Schritt-für-Schritt-Tour",
    STEP_BY_STEP_TOUR_DESC:
      "Hallo Benutzer! Wir haben erkannt, dass dies Ihr erster Besuch hier ist. Diese Tour wird Sie durch die verschiedenen Bereiche führen und erklären, wie alles funktioniert. Wenn Sie die Tour überspringen möchten, können Sie dies tun, indem Sie auf die Schaltfläche 'Überspringen' unten klicken.",
    STEP_BY_STEP_TOUR_SKIP: "Überspringen",
    STEP_BY_STEP_TOUR_START: "Starten",
    STEP_BY_STEP_TOUR_CREATE_QR: "QR-Code erstellen",
    STEP_BY_STEP_TOUR_GET_STARTED_CREATE_QR_CODE: "Um einen QR-Code zu erstellen, können Sie die oben stehende Schaltfläche verwenden.",
},
  POPUP: {
    error_title: "Fehler!",
    CHOOSE_ONE: "QR-Code-Typ wählen",
    TRANSPARENT_QR_CODE: "Transparenter QR-Code",
    STANDART_QR_CODE: "Standard QR-Code",
    BANNER_QR_CODE: "Banner QR-Code",
    GO_BACK: "Zurück",
    QR_CODE_BACKGROUND_PICTURE: "Ich möchte mein QR-Code mit einem benutzerdefinierten Hintergrundbild versehen",
    QR_CODE_COLOR: "Ich möchte das Aussehen und die Farben des QR-Codes anpassen",
    QR_CODE_BANNER: "Ich möchte ein Banner erstellen und die QR-Code-Größe anpassen",
    upgradeContent: ({ named }) =>
      `<div class="illustrated-bg d-flex flex-column justify-content-center align-items-center">
      <img width="200px" src="https://vue.qrcodematic.com/backgroundImages/popup01/istockphoto-1176277785-612x612_preview-1686842223.jpg">
      <h1 class="text-h5"><span style="font-weight: 900;" class="button_green--text text-h4">Mehr</span> <span style="font-weight: 200; color:#919191">erstellen, indem Sie auf</span> <strong class="text-h4 button_green--text">Upgrade</strong> klicken</h1>
      <p style="font-size: 1rem; max-width: 360px; text-align: justify;">Sie haben das Limit für die <strong class="orange--text">${named(
        "total"
      )}</strong> QR-Codes in Ihrem Paket erreicht. Um mehr QR-Codes zu erstellen, können Sie unsere Premium-Pakete prüfen.</p>
      <button id="alert-callback" style="width: 180px; border-radius: 5px; background: var(--v-button_green-base)" class="mt-5 pa-2 white--text d-flex justify-content-center align-items-center">Upgrade <i class="fa-solid fa-rectangle-pro ml-2"></i></button>
    </div>`,
    packageController: ({ named }) => `
    <div class="illustrated-bg d-flex flex-column justify-content-center align-items-center">
      <h1 class="text-h5">Ihr Paket ist abgelaufen!</h1>
      <p style="width: 300px; text-align: justify"><strong class="button_green--text"><i class="fa-solid fa-rectangle-pro fa-lg"></i> Paket</strong> kaufen und QR Code Matic weiterhin nutzen.</p>
      <button id="alert-callback" style="width: 180px; border-radius: 5px; background: var(--v-button_green-base)" class="mt-5 pa-2 white--text d-flex justify-content-center align-items-center">Upgrade <i class="fa-solid fa-rectangle-pro ml-2"></i></button>
    </div>
    `,
    upgradeToPro: ({ named }) => `
    <div class="illustrated-bg d-flex flex-column justify-content-center align-items-center">
      <h1 class="text-h5">Diese Funktion ist nur für <strong style="color: tomato">PRO</strong> verfügbar</h1>
      <p style="width: 300px; text-align: justify"><strong class="button_green--text"><i class="fa-solid fa-rectangle-pro fa-lg"></i> Paket</strong> kaufen und QR Code Matic weiterhin nutzen.</p>
      <button id="alert-callback" style="width: 180px; border-radius: 5px; background: var(--v-button_green-base)" class="mt-5 pa-2 white--text d-flex justify-content-center align-items-center">Upgrade <i class="fa-solid fa-rectangle-pro ml-2"></i></button>
    </div>
    `,
    allowedTypeController: ({ named }) => `
    <div class="illustrated-bg d-flex flex-column justify-content-center align-items-center">
      <h1 class="text-h5"><strong class="orange--text">${named(
        "typeName"
      )}</strong> weiterhin nutzen? Upgrade erforderlich</h1>
      <p>Das Paket, das Sie verwenden, umfasst diesen QR-Code-Typ nicht. Wenn Sie auf den <strong>Pro</strong>-Plan upgraden, können Sie diesen und andere Premium-QR-Codes ohne Einschränkungen verwenden.</p>
      <button id="alert-callback" style="width: 180px; border-radius: 5px; background: var(--v-button_green-base)" class="mt-5 pa-2 white--text d-flex justify-content-center align-items-center">Upgrade <i class="fa-solid fa-rectangle-pro ml-2"></i></button>
    </div>
    `,
    getMoreInformation: ({ named }) => `
    <div>
     <p>Die Spracheinstellungen ermöglichen es Ihnen, verschiedene Informationen für jede von Ihnen gewählte Sprache einzugeben. Wenn Sie mehrere Sprachen hinzufügen, wird der Endbenutzer des QR-Codes im Live-Modus in der oberen rechten Ecke ein Sprachmenü sehen.</p>
     <p>So geht's;</p>
     <ol>
      <li>Klicken Sie zunächst auf die <span class="font-weight-bold">"+"</span> Schaltfläche</li>
      <li>Wählen Sie die Sprachen aus, die Sie hinzufügen möchten. Sie können mehrere Sprachen auswählen. Wählen Sie dann eine Sprache aus, in die der Inhalt kopiert werden soll. (Diese Option ist möglicherweise für diesen QR-Code-Typ nicht verfügbar.) Klicken Sie dann auf <span class="font-weight-bold">"OK"</span></li>
      <li>Nun können Sie auf die hinzugefügten Sprachflaggen klicken und die <span class="font-weight-bold">"Aktiv"</span>-Schaltfläche drücken. Auf diese Weise können Sie die Inhalte für diese bestimmte Sprache bearbeiten.</li>
      <li>Um eine hinzugefügte Sprache zu entfernen, klicken Sie auf die <span class="font-weight-bold">"Löschen"</span>-Schaltfläche. Denken Sie daran, dass mindestens eine Sprache vorhanden sein muss.</li>
      <li>Durch Klicken auf <span class="font-weight-bold">"Als Standard festlegen"</span> können Sie diese Sprache als Standard festlegen, sodass Benutzer, die Ihre Seite besuchen, in dieser Sprache begrüßt werden.</li>
     </ol>
    </div>
    `,
    getInfoAboutTitle: ({ named }) => `
    <div>
   <p class="text--justify" style="font-size: 14px">Ihr QR-Code-Titel wird auf der QR-Code-Listen-Seite unter dem Bild wie unten gezeigt angezeigt. Nachdem Sie Ihren QR-Code erstellt haben, werden Sie auf diese Seite weitergeleitet.<br>
   Den QR-Code-Titel können Sie später bearbeiten.</p>
   <div class="d-flex justify-content-center" style="width: 100%;">
      <img src="/media/tutorials/qrcode-title.png" style="max-width: 400px; object-fit: cover; border-radius: 5px; "/>
   </div>
  </div>
    `,
},
  BULK: {
    create_new_child: "Neue Unter-QR-Codes erstellen",
    update_childs_data: "Die Details meiner Unter-QR-Codes ändern",
    update_data: "Die allgemeinen Informationen aller meiner Unter-QR-Codes aktualisieren",
    zip_request: "Alle meine QR-Codes im SVG-Format herunterladen",
    zip_downloaded: "Zip-Datei wurde heruntergeladen!",
    download_master: "Master Excel-Datei herunterladen",
    update_config_button_text: "Die Farben, Designs und anderen Einstellungen aller meiner Unter-QR-Codes aktualisieren",
    update_data_description: () => `<p style="text-align: center">
    Wählen Sie die Felder unten aus, um die Unter-QR-Codes des Masters zu aktualisieren. Alle Unter-QR-Codes des Masters werden automatisch mit den Einstellungen der Excel-Datei (vcard-bulk-update) aktualisiert, die Sie ausgewählt haben.
  </p>
  <p style="text-align: center">
    Zum Beispiel; wenn Sie das <strong>Avatar-Feld</strong> auswählen und auf <strong>Aktualisieren</strong> klicken, wird der <strong>Avatar-Wert</strong>, den Sie in der Excel-Datei (vcard-bulk-update) angegeben haben, auf alle Unter-QR-Codes des Masters angewendet.
  </p>`,
    update_config_description: () => `<p>
  Dieser Vorgang dient dazu, die Änderungen, die Sie am <strong>Master</strong> vorgenommen haben, gleichzeitig auf die Unter-QR-Codes anzuwenden. Alle in der Master-Liste angegebenen Eigenschaften werden auf die Unter-QR-Codes angewendet.
  <ul>
    <li>Alle QR-Code-Farben</li>
    <li>Alle QR-Code-Designs</li>
  </ul>
</p>
<p>
  Wenn Sie die Aktualisierung durchführen, wird das Design aller Unter-QR-Codes mit dem Master-Design übereinstimmen.
</p>`,
    create_description: () => `Um Bulk-QR-Codes zu erstellen, ziehen Sie die Excel-Datei, die Sie bearbeitet haben, in den Upload-Bereich unten und klicken Sie auf die angezeigte <strong class="green--text">Hochladen</strong>-Schaltfläche.`,
    update_options_title: "Aktualisierungsoptionen",
    avatar: "Avatar",
    are_you_sure: "Bist du sicher?",
    see_qr_codes: "QR-Codes ansehen",
    see_qr_codes_tooltip: "Sie können die Unter-QR-Codes sehen und sie wieder zum Master zurücksetzen.",
    background: "Hintergrund",
    settings: "Einstellungen",
    advanced_settings: "Erweiterte Einstellungen",
    update_data_button_text: "Aktualisieren",
    update_config_button_text: "Design und Farben der Unter-QR-Codes aktualisieren",
    show_previously_uploaded: "Frühere Excel-Uploads anzeigen",
    reached_upload_limit: "Sie haben Ihr Upload-Limit erreicht. Sie müssen eine oder mehrere Dateien aus der Liste löschen, bevor Sie fortfahren können.",
    upload_error_text:
      "Die von Ihnen hochgeladene Excel-Datei enthält Daten im falschen Format. Bitte überprüfen Sie die fehlerhaften Felder unten und versuchen Sie erneut, die Datei hochzuladen.",
    back_to_upload: "Zurück zum Upload",
    select_from_list: "Wählen Sie unten die gewünschte Aktion aus",
  },
  NEWS: {
    firstVisitMessage: ({ named }) => `
    <p>Heute begrüßen wir euch mit einer aufregenden Nachricht: Wir haben unser QR-Code-System überarbeitet! Wir haben eine Reihe von Verbesserungen vorgenommen, um euch eine bessere Benutzererfahrung zu bieten.</p>
    <p>QR-Codes sind zu einem unverzichtbaren Werkzeug für schnellen und einfachen Informationsaustausch geworden. Wir entwickeln diese Technologie weiter, um euch den bestmöglichen Service zu bieten.</p>
    `,
    firstVisitFullMessage: ({ named }) => `
    <p>Heute begrüßen wir euch mit einer aufregenden Nachricht: Wir haben unser QR-Code-System überarbeitet! Wir haben eine Reihe von Verbesserungen vorgenommen, um euch eine bessere Benutzererfahrung zu bieten.</p>

    <p>QR-Codes sind zu einem unverzichtbaren Werkzeug für schnellen und einfachen Informationsaustausch geworden. Wir entwickeln diese Technologie weiter, um euch den bestmöglichen Service zu bieten.</p>

    <p>Unser neues QR-Code-System verfügt über ein benutzerfreundliches Design. Jetzt reicht es aus, weniger Schritte zu unternehmen, um euch anzumelden und QR-Codes zu erstellen. Wir haben die Benutzeroberfläche vereinfacht und euch von unnötigen Klicks befreit. Wir haben das Design neu strukturiert, um ein schnelles, flüssiges und problemloses Erlebnis zu bieten.</p>

    <p>Außerdem haben wir neue Funktionen hinzugefügt, um die QR-Codes funktionaler und flexibler zu gestalten. Jetzt könnt ihr die von euch erstellten QR-Codes mit noch mehr Daten personalisieren. Ihr könnt Links zu eurer Website oder sozialen Medien in den QR-Code einfügen, Menüs für eure köstlichen Gerichte erstellen oder eine vCard mit eurer E-Mail-Adresse oder Telefonnummer generieren. Wir bieten viele Optionen, um das Einsatzgebiet unserer QR-Codes zu erweitern.</p>

    <p>Die Sicherheit in diesem erweiterten Einsatzbereich hat für uns oberste Priorität. In unserem neuen QR-Code-System haben wir zusätzliche Sicherheitsmaßnahmen ergriffen, um eure Daten und Informationen zu schützen. Wir haben eine bessere Grundlage geschaffen, um die Sicherheit eurer persönlichen und sensiblen Daten zu gewährleisten.</p>

    <p>Die Geschwindigkeit unserer QR-Codes ist jetzt ebenfalls verbessert. Dank verbesserter Algorithmen und optimierter Systeme werdet ihr eure QR-Codes schneller scannen können und eure Vorgänge in kürzerer Zeit abschließen. Wir haben unsere technologische Infrastruktur aktualisiert, damit ihr eure Zeit effizienter nutzen könnt.</p>    

    <p>Die Rückmeldungen und Bedürfnisse unserer wertvollen Nutzer sind uns immer wichtig. Daher arbeiten wir kontinuierlich daran, euch in unserem neuen QR-Code-System eine noch bessere Erfahrung zu bieten. Wenn ihr Fragen, Vorschläge oder Feedback zu unserer Website habt, lasst es uns bitte wissen. Eure Zufriedenheit ist unser Erfolg. Willkommen!</p>
    `,

    HELLO: "Hallo, wir haben uns erneuert!",
    we_are_improving_to_provide_you_with_better_service:
      "Wir entwickeln uns weiter, um euch einen besseren Service zu bieten",
  },

TERMS: {
    terms_text: ({ named }) =>
      `
      <h1>QR Code Matic Nutzungsbedingungen</h1>
      <hr>
      <p>Die Nutzer, die diese QR-Code-Erstellungswebsite verwenden und QR-Codes erstellen, gelten als mit den folgenden Bedingungen einverstanden. Außerdem gehören alle Webseiten und damit verbundenen Seiten („Website“) der Firma Medyax Bilişim Hizmetleri mit Sitz in FSM Bulvarı Fethiye Mah. Fazıl Sk. No:8 D:2 Nilüfer Bursa und werden von ihr betrieben. Indem Sie („Nutzer“) die auf der Website angebotenen Dienstleistungen nutzen, erklären Sie, dass Sie die nachstehenden Bedingungen akzeptieren und dass Sie gemäß den geltenden Gesetzen das Recht, die Autorität und die rechtliche Fähigkeit besitzen, Verträge zu unterzeichnen und über 18 Jahre alt sind. Sie bestätigen, dass Sie diese Vereinbarung gelesen und verstanden haben und sich an die darin aufgeführten Bedingungen halten werden. Diese Vereinbarung legt die Rechte und Pflichten der Parteien im Zusammenhang mit der Website fest und beide Parteien erklären, dass sie diese Rechte und Pflichten vollständig, korrekt und rechtzeitig gemäß den Anforderungen dieser Vereinbarung erfüllen werden.</p>
      <p>Diese Vereinbarung legt die Rechte und Pflichten der Parteien im Zusammenhang mit der Website fest und beide Parteien erklären, dass sie diese Rechte und Pflichten vollständig, korrekt und rechtzeitig gemäß den Anforderungen dieser Vereinbarung erfüllen werden.</p>
      <p>
      <h2>1. VERANTWORTLICHKEITEN</h2>
        <p> a. Die Firma behält sich jederzeit das Recht vor, Preise und angebotene Produkte sowie Dienstleistungen zu ändern.</p>
        <p> b. Die Firma verpflichtet sich, dem Mitglied die Dienste der Website, abgesehen von technischen Störungen, zur Verfügung zu stellen.</p>
        <p> c. Der Nutzer erklärt sich damit einverstanden, keine Reverse Engineering-Techniken auf der Website anzuwenden, keine Quellcodes zu finden oder zu erlangen oder andere Maßnahmen zu ergreifen, um dies zu tun. Andernfalls ist der Nutzer für Schäden verantwortlich, die Dritten entstehen, und erklärt sich mit rechtlichen und strafrechtlichen Maßnahmen einverstanden.</p>
        <p> d. Der Nutzer erklärt sich damit einverstanden, auf der Website keine Inhalte zu erstellen oder zu teilen, die gegen die allgemeine Moral und Anstand, Gesetze, die Rechte Dritter, irreführend, aggressiv, obszön, pornografisch, die Persönlichkeitsrechte verletzend, Urheberrechtsverletzungen oder illegale Aktivitäten fördern. Andernfalls ist der Nutzer für alle daraus resultierenden Schäden vollständig verantwortlich und die Website behält sich das Recht vor, solche Konten auszusetzen oder zu schließen und rechtliche Schritte einzuleiten. In solchen Fällen wird die Website den entsprechenden Behörden Informationen zu den Aktivitäten oder Nutzerkonten zur Verfügung stellen, falls diese angefordert werden.</p>
        <p> e. Die Beziehungen zwischen den Nutzern und Dritten auf der Website liegen in der Verantwortung des jeweiligen Nutzers.</p>
      </p>

      <h2>2. Geistige Eigentumsrechte</h2>
    <p> 2.1. Alle geistigen Eigentumsrechte, einschließlich Marken, Patente, Designs, Systemcodes, Informationen und Methoden, die auf dieser Website enthalten sind, gehören dem Betreiber und Inhaber der Website oder dem angegebenen Inhaber und sind durch nationales und internationales Recht geschützt. Der Besuch dieser Website oder die Nutzung der angebotenen Dienste gewährt keine Rechte an diesen geistigen Eigentumsrechten.</p>
    <p>2.2. Die auf der Website enthaltenen Informationen dürfen unter keinen Umständen vervielfältigt, veröffentlicht, kopiert, übertragen oder auf andere Weise verwendet werden. Die Website oder Teile davon dürfen nicht ohne Erlaubnis auf einer anderen Website verwendet werden.</p>

  <h2>3. Vertrauliche Informationen</h2>
    <p> 3.1. Die Firma wird keine persönlichen Informationen der Nutzer, die über die Website übermittelt werden, an Dritte weitergeben. Diese persönlichen Informationen umfassen den Namen, die Adresse, die Telefonnummer, die Mobiltelefonnummer, die E-Mail-Adresse und alle anderen Informationen, die zur Identifikation des Nutzers dienen, und werden als "Vertrauliche Informationen" bezeichnet.</p>
    <p> 3.2. Der Nutzer erklärt sich damit einverstanden, dass die Firma seine eigenen Kommunikations-, Portfoliodaten und demografische Informationen nur für Marketingzwecke, wie Werbung, Kampagnen, Promotionen, Ankündigungen und ähnliche Aktivitäten, an Tochtergesellschaften oder verbundene Unternehmen weitergibt. Diese persönlichen Daten können verwendet werden, um Kundenprofile zu erstellen, passende Promotionen und Kampagnen anzubieten und statistische Studien durchzuführen.</p>
    <p>3.3. Vertrauliche Informationen dürfen nur dann an die zuständigen Behörden weitergegeben werden, wenn diese Informationen von den Behörden angefordert werden oder es eine gesetzliche Verpflichtung gibt, die Informationen gemäß den geltenden Vorschriften offenzulegen.</p>

  <h2>4. Keine Garantie</h2>
    <p>Diese Vereinbarung gilt im maximal zulässigen Umfang gemäß dem anwendbaren Gesetz. Die von der Firma angebotenen Dienstleistungen werden "wie sie sind" und "so weit verfügbar" bereitgestellt, ohne jegliche Garantie hinsichtlich der Marktgängigkeit, der Eignung für einen bestimmten Zweck oder der Nichtverletzung. Jegliche stillschweigende, ausdrückliche oder gesetzliche Garantie in Bezug auf die Dienstleistung wird ausgeschlossen.</p>

  <h2>5. Registrierung und Sicherheit</h2>
    <p> Der Nutzer muss genaue, vollständige und aktuelle Registrierungsinformationen angeben. Andernfalls wird diese Vereinbarung als verletzt betrachtet und der Nutzeraccount kann ohne Benachrichtigung geschlossen werden. Der Nutzer ist für die Sicherheit von Passwörtern und Konten auf der Website sowie auf Drittanbieter-Websites verantwortlich. Die Firma übernimmt keine Verantwortung für Datenverluste oder Sicherheitsverletzungen oder Schäden an Geräten. Vor dem Drucken von QR-Codes müssen diese auf verschiedenen Geräten getestet werden. Fehlerhafte Drucke oder Erstellungsprozesse liegen in der Verantwortung des Nutzers.</p>

  <h2>6. Höhere Gewalt</h2>
    <p>Die Parteien sind von jeglicher Haftung befreit, wenn die Vertragserfüllung aufgrund unvorhersehbarer Ereignisse wie Naturkatastrophen, Brände, Explosionen, Kriege, Unruhen, Streiks, Stromausfälle oder andere ähnliche Ursachen unmöglich wird. In solchen Fällen werden die Rechte und Pflichten der Parteien ausgesetzt.</p>

  <h2>7. Gültigkeit und Anwendbarkeit</h2>
    <p>Falls eine Bestimmung dieser Vereinbarung ganz oder teilweise ungültig wird, bleiben die übrigen Bestimmungen der Vereinbarung weiterhin gültig.</p>

  <h2>8. Änderungen der Vereinbarung</h2>
    <p>Die Firma behält sich das Recht vor, die Dienstleistungen und die Bedingungen dieser Vereinbarung jederzeit zu ändern. Änderungen werden ab dem Zeitpunkt der Veröffentlichung auf der Website wirksam. Es liegt in der Verantwortung des Nutzers, Änderungen zu verfolgen. Der Nutzer gilt als mit den Änderungen einverstanden, wenn er die angebotenen Dienste weiterhin nutzt.</p>

  <h2>9. Mitteilungen</h2>
    <p>Alle Mitteilungen im Zusammenhang mit dieser Vereinbarung werden per E-Mail an die vom Nutzer angegebene E-Mail-Adresse gesendet. Der Nutzer erklärt sich damit einverstanden, dass die angegebene Adresse als gültige Mitteilungsadresse gilt und dass Änderungen innerhalb von 5 Tagen schriftlich mitgeteilt werden müssen. Andernfalls gelten Mitteilungen, die an diese Adresse gesendet werden, als gültig.</p>
  
    <h2>10. Beweiskraft</h2>
    <p>Im Falle von Streitigkeiten, die sich aus dieser Vereinbarung ergeben, werden die Bücher, Aufzeichnungen und Dokumente der Parteien sowie Computeraufzeichnungen und Faxaufzeichnungen gemäß dem türkischen Gesetz über Zivilverfahren (Gesetz Nr. 6100) als Beweismittel anerkannt. Der Nutzer erklärt sich damit einverstanden, gegen diese Aufzeichnungen keine Einwände zu erheben.</p>

  <h2>11. Streitbeilegung</h2>
    <p>Alle Streitigkeiten, die sich aus der Anwendung oder Auslegung dieser Vereinbarung ergeben, unterliegen der Gerichtsbarkeit der Gerichte und Vollstreckungsbehörden der Stadt Bursa (Zentrum).</p>

  <h2>12. Gebühren</h2>
    <p>Bei der ersten Registrierung im System wird dem Nutzer ein Testpaket zugewiesen, damit er die Funktionen testen kann. Die Gültigkeit des Testpakets und der erstellten digitalen Visitenkarten beträgt 5 Tage und Ausgaben können im PNG-Format erstellt werden. Der Nutzer kann beim Erstellen von QR-Codes den Typ auf der endgültigen Seite auswählen. Die Auswahl des QR-Code-Typs liegt in der Verantwortung des Nutzers. Vektor-Ausgaben sind nur für die Business-Pakete verfügbar. QRCodeMatic behält sich das Recht vor, Änderungen an der Testpaket-Dauer vorzunehmen. Am Ende des 5-Tages-Zeitraums muss der Nutzer ein kostenpflichtiges Paket auswählen, um weiterhin QR-Codes zu verwenden. Andernfalls werden die erstellten QR-Codes gelöscht.</p>
  
    <h2>13. Kontolöschung</h2>
    <p>Der Nutzer kann sein Konto löschen, indem er sich anmeldet und die Option „Konto löschen“ im Menü „Mein Konto“ auswählt oder eine Anfrage zur Löschung des Kontos an info@qrcodematic.com sendet. Der Nutzer erklärt sich damit einverstanden, dass bei Löschung des Kontos alle erstellten Inhalte und QR-Codes dauerhaft gelöscht werden.</p>
    <p>Vor dem Senden Ihrer QR-Codes zum Druck sollten Sie diese auf verschiedenen Geräten testen, um sicherzustellen, dass sie korrekt funktionieren. Bitte senden Sie den Druckauftrag nicht ohne vorherige Tests. Durch das Erstellen eines Kontos und das Akzeptieren dieser Bedingungen stimmen Sie zu, dass Sie alle Bedingungen gelesen, verstanden und akzeptiert haben.</p>
   `,
},
CROPPER: {
    confirm_selected_area: "Bereich anwenden",
    confirm_selection_tooltip: "Bestätigt den ausgewählten Bereich",
    cropper_success: "Erfolgreich angewendet",
  },
  STATISTICS: {
    show_all: "Alle anzeigen",
    last_x_days: ({ named }) => `Letzte ${named("days")} Tage`,
    trial_warning:
      "<strong>DEMO: </strong> Die untenstehenden Statistiken werden <strong>in einem Testkonto</strong> als Beispiel angezeigt.",
  },
  HELP: {},
  AUTH: {
    GENERAL: {
      OR: "Oder",
      SUBMIT_BUTTON: "Absenden",
      NO_ACCOUNT: "Kein Konto?",
      SIGNUP_BUTTON: "Registrieren",
      FORGOT_BUTTON: "Passwort vergessen?",
      BACK_BUTTON: "Zurück",
      PRIVACY: "Datenschutz",
      LEGAL: "Rechtliches",
      CONTACT: "Kontakt",
    },
    LOGIN: {
      TITLE: "Konto anmelden",
      BUTTON: "Registrieren",
    },
    FORGOT: {
      TITLE: "Passwort vergessen?",
      DESC: "Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen",
      SUCCESS: "Ihr Konto wurde erfolgreich zurückgesetzt.",
    },
    REGISTER: {
      TITLE: "Registrieren",
      DESC: "Geben Sie Ihre Daten ein, um ein Konto zu erstellen",
      SUCCESS: "Ihr Konto wurde erfolgreich erstellt.",
    },
    INPUT: {
      EMAIL: "E-Mail",
      FULLNAME: "Vor- und Nachname",
      PASSWORD: "Passwort",
      CONFIRM_PASSWORD: "Passwort bestätigen",
      USERNAME: "Benutzername",
    },
    VALIDATION: {
      INVALID: "{{name}} ist ungültig",
      REQUIRED: "{{name}} ist erforderlich",
      MIN_LENGTH: "{{name}} Mindestlänge ist {{min}}",
      AGREEMENT_REQUIRED: "Die Akzeptanz der Allgemeinen Geschäftsbedingungen ist erforderlich",
      NOT_FOUND: "{{name}} nicht gefunden",
      INVALID_LOGIN: "Anmeldedaten sind ungültig",
      REQUIRED_FIELD: "Pflichtfeld",
      MIN_LENGTH_FIELD: "Minimale Feldlänge:",
      MAX_LENGTH_FIELD: "Maximale Feldlänge:",
      INVALID_FIELD: "Feld ist ungültig",
    },
  },
  ICONSELECT: {
    animals: "Tiere",
    landscape: "Landschaft",
    people: "Menschen",
    vehicles: "Fahrzeuge",
    communication: "Kommunikation",
    technology: "Technologie",
    business: "iKatalog / Menü",
    food: "Essen",
  }
};
